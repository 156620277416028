/*

  color mapping for better colors in face etc.
  https://jsfiddle.net/bigtimebuddy/57w8mnvz/
  
*/
import React,{ useState, useEffect, useLayoutEffect, Suspense, lazy } from "react";
import {
//  BrowserRouter as Router,
  HashRouter as Router,
  Route,
  Routes,
  useRoutes,
  useNavigate
} from "react-router-dom";

import * as PIXI from 'pixi.js-legacy'

import { gsap } from "gsap/all";
import { PixiPlugin } from "gsap/PixiPlugin";
//import { SplitText } from "gsap/SplitText";
import { appApi } from './store/appstore'
import { useLoadApi } from './store/preloaderstore'

import PreLoader from "./components/preloader";
import PreProcessing from "./components/PreProcessing"
//import WelcomeBack from "./components/WelcomeBack"

//import Routing from './RoutingTest'
import Routing from './Routing'
import "iframe-resizer/js/iframeResizer.contentWindow";
//import analytics from './analytics'
import './App.css';

//gsap.registerPlugin(SplitText);

gsap.registerPlugin(PixiPlugin);
PixiPlugin.registerPIXI(PIXI);

appApi.getState().init()
 

if ('parentIFrame' in window) window.parentIFrame.size( 690 );
if ('parentIFrame' in window) window.parentIFrame.autoResize(false);

//scormApi.getState().init();

function App() {
  const canContinue = useLoadApi(state => state.canContinue)
  const [allLoaded, setAllLoaded] = useState(false);
  const [messageData, setMessageData] = useState()

  useEffect(() => {
    console.log("app allLoaded:",canContinue)
    
    if( canContinue ) {
      setAllLoaded(true)
    } else {
    }

    if ('parentIFrame' in window) window.parentIFrame.size( 690 );
    if ('parentIFrame' in window) window.parentIFrame.autoResize(false);
    
    return ()=>{
    }
  },[canContinue]);
  

  const onResized = data => setMessageData(data)

  const onMessage = data => {
    console.log(data)
//    setMessageData(data)
//    iframeRef.current.sendMessage('Hello back from the parent page')
  }  
  return (
    <>
      <Router>
        <PreLoader />
        <PreProcessing />
        
        {allLoaded && <Routing />}
      </Router>


    </>
  );
}

export default App;
