import React from 'react';
import { useLoadApi } from '../store/preloaderstore'
import { appApi } from '../store/appstore'
import { gsap } from "gsap/all";

import './TopSpace.css';

class TopSpace extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
        this.panel = React.createRef();
    }

    componentDidMount() {
        this.unsub1 = useLoadApi.subscribe(manposition => {
            console.log("   TopSpace -> position")
//            let manO = manposition
            this.update()
        }, state => state.manposition)
    }
    componentWillUnmount() {
        this.unsub1()
    }

    update() {
        let manO = useLoadApi.getState().manposition2, yOff = 0
        if(!manO)manO = useLoadApi.getState().manposition
        if(this.props.yOffset)yOff = this.props.yOffset
        if(manO) {
            let sy = manO.bottom, sh = window.screen.height - sy
            gsap.set(this.panel.current,{height:sy+ yOff});
        }
    }

    render() {
        let mstyle = {}
        if(this.props.noMouseOrTouch) {
            mstyle.pointerEvents = "none"
            mstyle.touchAction = "none"
        }
        return (
            <div className='topspace' style={mstyle} ref={this.panel}></div>
        )
    }
}

export default TopSpace;
