/*
    
    https://github.com/TJCoding/Enhanced-Image-Colour-Transfer-2
    
*/
import React from 'react';
import { gsap } from "gsap/all";
import * as PIXI from 'pixi.js-legacy'
//import hotkeys from 'hotkeys-js';
import { isMobile } from 'mobile-device-detect';
import { loadApi } from '../store/preloaderstore'
import { appApi } from '../store/appstore'

//import {FXAAFilter} from  './../filters/filter-fxaa';
import './ManipulationSceneCamera.css';

let isIOS = (/iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) && !window.MSStream
let isSafari = /^((?!chrome|android).)*safari/i.test(window.navigator.userAgent);

const ls = require('local-storage');

class ManipulationSceneEndLabel9x16 extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showGrid:true,
        };

        this.cRef = React.createRef();

        this.PConfig = {
            forceCanvas:true,
            backgroundAlpha:0,
            resolution: 1,
            antialias:true,
            autoResize:false,
            autoDensity: false,
            width:this.props.canvwidth,
            height:this.props.canvheight,
        }
        console.log("mobile config")

        if(0 === 1 ) {
            this.PConfig = {
                backgroundAlpha:0,
                resolution: 1,
                antialias:true,
                autoResize:true,
                autoDensity: true,
                resizeTo:window,
            }
            console.log("desktop config")
        }

        this.app = new PIXI.Application(this.PConfig);

        //-- global ref!
//        window._PIXI_APP = this.app
        
        this.centerP1 = {x:0,y:0}

        this._handleResize = this.handleResize.bind(this)
        this._handleResize2 = this.handleResize2.bind(this)

        this.iLayer = null;

//        this.myPic = null;

        this.bmManWHRatio = 1;// 0.94765343

        this.camTexture = null
        this.videoCont = null;

        this.man = null;

        //-- man offset center face
        this.manAncX = 0.54472;
        this.manAncY = 0.3174;
        

        this._YEAR = "1985"

        this.isMobile = isMobile

        this.renderMultiplier = this.props.renderMultiplier === undefined ? 1 : this.props.renderMultiplier

        this.labelYOffset = 50 ;
        this.calculatedYOffset = 96 + this.labelYOffset;

    }

    componentDidMount() {
        console.log("ManipulationSceneCamera -> componentDidMount")

        
        try {
            this.createLabelComposition( this.props.canvwidth,this.props.canvheight, this.app.stage, this.props.renderBottle )
            this.cRef.current.appendChild(this.app.view)
            if( this.props.autoTouch) {
                this.app.renderer.plugins.interaction.autoPreventDefault = false;
                this.app.view.style.touchAction = 'auto';
            }
        } catch(err){ console.error(err)}

        if(this.isMobile) {
            window.addEventListener('orientationchange', this._handleResize2);
        } else {
            window.addEventListener('resize', this._handleResize)
        }

        //-- do skintone!
        this.checkSkinTone();

        if(this.props.componentReady){
            console.log("calling this.props.componentReady")
            this.props.componentReady(loadApi.getState().manposition)
        }
    }
    componentWillUnmount() {
        if(this.isMobile) {
            window.removeEventListener('orientationchange', this._handleResize2);
        } else {
            window.removeEventListener('resize', this._handleResize)
        }
        if(this.app) {
            this.cRef.current.removeChild(this.app.view)
        }

        if(this.unsub1)this.unsub1()
    }

    handleResize(e) {
    };
    handleResize2(e) {
    }

    //--
    //--    createFromBase64
    //--
    createFromBase64(photo) {

//        let manO = loadApi.getState().manposition

        var image = new Image();
        image.onload = () => {

            this.photo.removeChildren()
            
            const base = new PIXI.BaseTexture(image);
            const texture = new PIXI.Texture(base);
            console.log(this.props.canvwidth , " | ", base.width,base.height,texture.width,texture.height)
            
//            this.myPic = texture
            
            let tw = texture.width,
                th = texture.height,
//                ratio2 = this.props.canvwidth / tw
                ratio2 = window.screen.width / tw

            let myPic = new PIXI.Sprite(texture);
            myPic.width = tw
            myPic.height = th
            myPic.scale.x *= -1;
            myPic.x = tw/2
            myPic.y = -th/2
            
            console.log("myPic.width:",myPic.width," myPic.height:",myPic.height)
            //-- scale and set correct position
            let picScaledCont = new PIXI.Container()
            picScaledCont.addChild(myPic)
            //-- apply scaling factors, as in createCameraTexture
            picScaledCont.scale.x = picScaledCont.scale.y = ratio2 * loadApi.getState().cameraScaleFactor 
//            picScaledCont.scale.x = picScaledCont.scale.y = ratio2 * loadApi.getState().cameraScaleFactor * 0.95
            
            console.log("picScaledCont.width:",picScaledCont.width," picScaledCont.height:",picScaledCont.height)
            
            this.photo.addChild( picScaledCont )
            
            if(this.props.loadingFaceReady) {
                gsap.delayedCall(1,()=>{
                    this.props.loadingFaceReady()
                })
            }
        }
        image.src = photo
    }

    //--
    //--    setYear
    //--
    setYear(y) {
        this._YEAR = y;
        if(this.txtYear) {
            console.log("setYear:",y)
            this.txtYear.text = "DAL " + y
        }
    }

    //--
    //--    createLabelComposition
    //--
    createLabelComposition(stageW,stageH,renderDest,renderBottle) {
        let manO = null, 
            screenW = stageW, 
            screenH = stageH, 
            w2 = stageW/2, 
            h2 = stageH/2, 
            screenRealW = window.screen.width,
            lapi = loadApi.getState()

        this.finalLayer = new PIXI.Container();
        this.finalLabelLayerWrap = new PIXI.Container();
        this.finalLabelLayer = new PIXI.Container();

        this.iLayer = new PIXI.Container();
        var oLay = new PIXI.Container();
        let oLayMask = new PIXI.Container();
        this.photo = new PIXI.Container();
        this.iLayer.addChild( this.photo );
        this.mLayer = new PIXI.Container();
        this.textLayer = new PIXI.Container();

        //-- RENDER BOTTLE AS BACKGROUND!
        if(renderBottle === 1 ) {

            let bigBottle = PIXI.Sprite.from(PIXI.Loader.shared.resources.bigBottle2.texture)
            bigBottle.x = 0;
            bigBottle.y = 0;
            this.finalLayer.addChild( bigBottle )
        }

        const manExtraSW = loadApi.getState().manExtraWidthRelWS

        //-- RENDER MAN
        let bmMan = PIXI.Sprite.from(PIXI.Loader.shared.resources.bmMan3.texture)
        bmMan.anchor.set(this.manAncX, this.manAncY);
//            bmMan.alpha = 0.4
        bmMan.x = 0
        bmMan.y = 0;
//        bmMan.filters = [FXAAFilter]
        this.iLayer.x = bmMan.x
        this.iLayer.y = bmMan.y
        this.mLayer.x = bmMan.x
        this.mLayer.y = bmMan.y

        let bmanOW = bmMan.width,
            bmanOH = bmMan.height,
            bmanBW = screenRealW + manExtraSW,
            bmanBH = bmanOH * (bmanBW/bmanOW)

        bmMan.width = bmanBW
        bmMan.height = bmanBH
        oLay.addChild( bmMan )
        
        this.man = bmMan;

//            bmMan.filters = [ filter ];
        manO = {scaleF:bmanBW / screenRealW,olx:oLay.x,oly:oLay.y}

        const bmMM = new PIXI.Graphics();
        bmMM.lineStyle(0);
        bmMM.beginFill(0xff0000);
        bmMM.lineTo(0,230)
        bmMM.lineTo(867,230)
        bmMM.lineTo(1038,317)
        bmMM.lineTo(1365,298)
        bmMM.lineTo(1365,651)
        bmMM.lineTo(1281,686)
        bmMM.lineTo(704,727)
        bmMM.lineTo(506,617)
        bmMM.lineTo(0,914)
        bmMM.scale.set(bmMan.scale.x) 
        bmMM.x = -(bmMan.width*this.manAncX) + bmMan.x
        bmMM.y = -(bmMan.height*this.manAncY)   //-- use height if bmMan!
        oLayMask.addChild(bmMM)
        oLayMask.y += bmMan.y

        let bmMoustache = null;
        bmMoustache = PIXI.Sprite.from(PIXI.Loader.shared.resources.moustache.texture)
        bmMoustache.anchor.set(0.5, 0.5);
        bmMoustache.scale.set(0.3 * this.renderMultiplier)
        bmMoustache.x = 0
        bmMoustache.y = 0

        this.mLayer.addChild( bmMoustache )

        //-- add Name to it!
        let contName = new PIXI.Container();
        //-- add NAME
        let textStyleName = new PIXI.TextStyle({
                            fontFamily: 'birra-body',
                            fontWeight: "normal",
//                            fontSize: bmMan.scale.x*320,
                            fontSize: bmMan.scale.x*400,
                            fill: 0x936c21,
                            align: 'center',
                        });
        let txtName = new PIXI.Text(this._name, textStyleName);
        txtName.anchor.set(0.5, 0.5);
        this.txtName = txtName
        contName.addChild(txtName)
//        contName.x = w2
        contName.y = bmMan.y + (bmMan.scale.x * (1070+60))
        this.finalLabelLayer.addChild( contName )

        //-- add Year of birth to it!
        let contDal = new PIXI.Container();
        let dal = null;
        dal = PIXI.Sprite.from(PIXI.Loader.shared.resources.dalDash.texture)
        dal.anchor.set(0.5, 0.5);
//        dal.scale.set(bmMan.scale.x*2.4)
        dal.scale.set(bmMan.scale.x*3.1)
        contDal.addChild( dal )
        //-- add YEAR
        let textStyle = new PIXI.TextStyle({
            //                fontFamily: 'Arial',
                            fontFamily: 'birra-body',
                            fontWeight: "normal",
//                            fontSize: bmMan.scale.x*180,
                            fontSize: bmMan.scale.x*220,
                            fill: 0xaa8827,
                            align: 'center',
                        });
        let txtYear = new PIXI.Text("DAL " + this._YEAR, textStyle);
        txtYear.anchor.set(0.5, 0.5);
        this.txtYear = txtYear
        contDal.addChild(this.txtYear)
//        contDal.x = w2
//        contDal.y = bmMan.y + (bmMan.scale.x * 1070)
        contDal.y = bmMan.y + (bmMan.scale.x * (1070 + 350))
        this.finalLabelLayer.addChild( contDal )
        
        
        //-- add to finalLabelLayer
        this.finalLabelLayer.addChild( this.iLayer )
        this.finalLabelLayer.addChild( this.mLayer )
        this.finalLabelLayer.addChild( oLay )
        this.finalLabelLayer.addChild( oLayMask )
//        this.finalLabelLayer.addChild( this.textLayer )


        this.photo.mask = oLayMask
        
/*
        var line = new PIXI.Graphics();
        line.lineStyle(2, 0xff0000, 1);
        line.moveTo(-500, 0);
        line.lineTo(500, 0);
        this.finalLabelLayer.addChild(line)
*/

        //-- add to final layer
        this.finalLabelLayerWrap.addChild( this.finalLabelLayer )
        this.finalLayer.addChild( this.finalLabelLayerWrap )
        
        //-- make label fit to bottle 
        if(renderBottle === 1 ) {
            this.setNameText("NAME")

            //-- all based on 390 width, so we need to calculate the extra scale factor for this.
            let wdif = screenRealW-390, wsf = 1
            if( wdif !== 0 ) {
                wsf = 1 - (wdif / 390)
            }
//            console.log( screenRealW, 390 , 390 - screenRealW, screenW, wsf )
//            console.log("extra scaling factor for final scaling and positioning:",wsf)

            this.finalLabelLayerWrap.scale.set(0.48 * wsf)
//            this.finalLabelLayer.scale.set(1)

            this.finalLabelLayerWrap.x =  w2;
            this.finalLabelLayerWrap.y = 1170;
        }

        //-- due to 50 pixels extra with in take picture scene, we need to correct this by scaling more down
//        let scaleCorrTPWS = 0.439
//        let scaleCorrTPWS2 = 1.2
//        let scaleCorrTPWS = 1.0 - (manExtraSW / screenW)
        let scaleCorrTPWS = 1.0 - (manExtraSW / screenRealW)

        //--  FACE!

        let newScale = lapi.imgScale
        if(!newScale)newScale = ls.get("headScale")
        if( newScale ) {
            console.log("FACE scale:",newScale,scaleCorrTPWS)
            //-- scale is based on other dimensions
            this.iLayer.scale.set( newScale * manO.scaleF * scaleCorrTPWS);
//            this.iLayer.scale.set( newScale * scaleCorrTPWS);
        } else {
            console.log("NO FACE scale:")
            this.iLayer.scale.set( manO.scaleF * scaleCorrTPWS )
        }

        let rad = lapi.imgRot
        if(!rad)rad = ls.get("headRot")
        if(rad) this.iLayer.rotation = rad;
        
        let imgPos = lapi.imgPos
        console.log("lapi.imgPos:",imgPos)
        if(!imgPos){ 
            imgPos = ls.get("headPos")
            console.log("ls.get('headPos'):",imgPos)
        }
        if( imgPos ) {
            this.iLayer.x += imgPos.dx * manO.scaleF * scaleCorrTPWS * this.renderMultiplier
            this.iLayer.y += imgPos.dy * manO.scaleF * scaleCorrTPWS * this.renderMultiplier
        }
        
//        console.log("Head scale:",newScale," rotation:",rad," pos:",imgPos)

        //-- MOUSTAGE

        newScale = lapi.moustacheScale
        if(!newScale)newScale = ls.get("moustacheScale")
        if( newScale ) {
            console.log("moustache scale:",newScale)
            this.mLayer.scale.set( newScale * manO.scaleF * scaleCorrTPWS );
        } else {
            console.log("no moustache scale")
            this.mLayer.scale.set( manO.scaleF * scaleCorrTPWS );
        }
        
        rad = lapi.moustacheRot
        if(!rad)rad = ls.get("moustacheRot")
        if(rad) this.mLayer.rotation = rad;

        imgPos = lapi.moustachePos
        if(!imgPos)imgPos = ls.get("moustachePos")
        if( imgPos ) {
            console.log("moustache position:",imgPos)
            this.mLayer.x += (imgPos.dx * manO.scaleF * scaleCorrTPWS) * this.renderMultiplier
            this.mLayer.y += (imgPos.dy * manO.scaleF * scaleCorrTPWS) * this.renderMultiplier
        } else {
            console.log("NO moustache position found")
            this.mLayer.x += -(0 * manO.scaleF * scaleCorrTPWS) * this.renderMultiplier
            this.mLayer.y += (20 * manO.scaleF * scaleCorrTPWS) * this.renderMultiplier
        }

        loadApi.getState().setManPos(manO)

        //-- add everything to stage
        renderDest.addChild( this.finalLayer )

        return manO
    }


    checkSkinTone() {
        this.unsub1 = appApi.subscribe(skintone => {
            this.setSkinToneByNr(skintone)
        }, state => state.skintone)
        
        let skintone = appApi.getState().skintone
        this.setSkinToneByNr(skintone)
    }
    setSkinToneByNr(skintone) {
        if(this.man) {
            if( skintone === 1 ) {
                this.man.texture = PIXI.Loader.shared.resources.bmMan1.texture;
            }
            if( skintone === 2 ) {
                this.man.texture = PIXI.Loader.shared.resources.bmMan2.texture;
            }
            if( skintone === 3 ) {
                this.man.texture = PIXI.Loader.shared.resources.bmMan3.texture;
            }
            if( skintone === 4 ) {
                this.man.texture = PIXI.Loader.shared.resources.bmMan4.texture;
            }
            if( skintone === 5 ) {
                this.man.texture = PIXI.Loader.shared.resources.bmMan5.texture;
            }
            if( skintone === 6 ) {
                this.man.texture = PIXI.Loader.shared.resources.bmMan6.texture;
            }
        }
    }


    //--
    //--    createLabelImageBig
    //--
/*
    createLabelImageBig() {
        if(!this.app) return
        
        let renderer = this.app.renderer,
            compW = 512, 
            compH = Math.floor(compW*1.2),
            renderTexture = PIXI.RenderTexture.create({width:compW, height:compH, resolution:1}),
            finalLayer = new PIXI.Container()
        
        try {
            this.createLabelComposition(compW,compH,finalLayer,0)
        } catch(err){ console.error(err)}

        renderer.render(finalLayer, {renderTexture});

        let canvas = renderer.extract.canvas(renderTexture);
//        let canv = renderTexture.getCanvas()
        return canvas.toDataURL()
    }
*/
    getCompositionData() {
        if(!this.composition) return null;
        var canvas = this.composition.getCanvas();
        return canvas.toDataURL()
    }

    getFinalCompositionImage() {
        var canvas = this.app.view;
        return canvas.toDataURL()
    }

    setNameText(text) {
        this._name = text
        if(this.txtName){
            this.txtName.text = text
        }
    }

    setNameText2(text) {
        this._name = text
        this.updateNameTextLayer2(text)
    }
    updateNameTextLayer2(text) {

    }

    //---------------------------------------------------------------------------------------

    async loadImage( src ) {
        const img = new Image();
        await new Promise(r => {img.onload=r; img.src=src});
        return img;
    }

    convVWtoPix(p,w) {
        return Math.round((w / 100.0) * p)
    }

    async renderDynamicTextToCanvas(renderimg,hastransname,normalBD,nameText,bdText) {

        console.log("--------------------- renderDynamicTextToCanvas:",renderimg)

        let renderTextOnly = false;
        if( renderimg === undefined || renderimg === null ) {
            renderTextOnly = true
            console.error("renderimg is undefined", renderimg)
//            return null;
        } else  {
            console.log("hastransname:",hastransname)
            console.log("nameText:",nameText)
            console.log("bdText:",bdText)
        }
        //-- check the height of both text to get the final height + all headers etc.
        const pConf = JSON.parse(JSON.stringify(this.PConfig))
        const compW = pConf.width = this.props.canvwidth
        let compH = pConf.height = this.props.canvheight*2
        if(renderTextOnly)compH = pConf.height = this.props.canvheight
        const tapp = new PIXI.Application(pConf);
        const cont = new PIXI.Container()
        
        tapp.stage.addChild(cont)

        //-- background yellow color
        const bgr = new PIXI.Graphics();
        bgr.beginFill(0xfff9e0);
        bgr.drawRect(0, 0, compW, compH);
        bgr.endFill();
        cont.addChild(bgr)
        
        let tw = compW, th = 0
        //-- load bottle image
        if( !renderTextOnly && renderimg) {
            const bottleImg = await this.loadImage( renderimg );
    //        console.log(bottleImg)
            //-- add the bottle
            const base = new PIXI.BaseTexture(bottleImg);
            const texture = new PIXI.Texture(base);
            tw = texture.width;
            th = texture.height;
            const endBottle = new PIXI.Sprite(texture);
            endBottle.x = 0;
            endBottle.y = 0;

    //        console.log("tw:",tw,"th:",th)

            cont.addChild(endBottle);
        }

        //-- create layer for text stuff
        const tLayer = new PIXI.Container();
        cont.addChild(tLayer);
        tLayer.y = th + 10

        //-- 7% left and right
        const lrM = Math.floor( (tw/100.0) * 7.0)
        const yOff = th + 10
        const twWrap = compW - (lrM*2)
        let nY = 0;
        
        //-- [font/Roves Sans Bold Rough.otf - #4f2c1d - 7vw / 7vw] an icon was born
        const style1 = new PIXI.TextStyle({
            fontFamily: 'birraBase',
            fontWeight: "normal",
            fontSize: this.convVWtoPix(7,tw),
            lineHeight: this.convVWtoPix(7,tw),
            fill: 0x4f2c1d,
            align: 'left',
        });
//        const text1 = "an icon was born";
        const text1 = "an authentic italian";
        const tm1 = PIXI.TextMetrics.measureText(text1, style1);
        const t1 = new PIXI.Text(text1, style1);
        t1.x = lrM
        tLayer.addChild(t1)
        nY = t1.y + tm1.height

        //-- [font/ChunkFive-Regular.otf - #93272c - 12.2vw / 12vw] NAME 
        const style2 = new PIXI.TextStyle({
            fontFamily: 'ChunkFive',
            fontWeight: "normal",
            fontSize: this.convVWtoPix(12.2,tw),
            lineHeight: this.convVWtoPix(12,tw),
            fill: 0x93272c,
            align: 'left',
        });
        const text2 = this._name.split("").reverse().join("").replace(/ /, '\n').split("").reverse().join("");
        const tm2 = PIXI.TextMetrics.measureText(text2, style2);
        const t2 = new PIXI.Text(text2, style2);
        t2.x = lrM
        t2.y = nY + 10
        tLayer.addChild(t2)
        nY = t2.y + tm2.height

        //-- [font/Roves Sans Bold Rough.otf - #b58500 - 7vw / 7vw] BIRTH DATE
        const style3 = new PIXI.TextStyle({
            fontFamily: 'birraBase',
            fontWeight: "normal",
            fontSize: this.convVWtoPix(7,tw),
            lineHeight: this.convVWtoPix(7,tw),
            fill: 0xb58500,
            align: 'left',
        });
        const text3 = normalBD
        const tm3 = PIXI.TextMetrics.measureText(text3, style3);
        const t3 = new PIXI.Text(text3, style3);
        t3.x = lrM
        t3.y = nY + 10
        tLayer.addChild(t3)
        nY = t3.y + tm3.height

        if( hastransname )
        {
            nY += 40;

            const bLayer = new PIXI.Container();
            const bLayerT = new PIXI.Container();
            const bLayerEnd = new PIXI.Container();

            bLayerEnd.addChild( bLayer )
            bLayerEnd.addChild( bLayerT )

            tLayer.addChild(bLayerEnd)
            bLayerEnd.y = nY

            let sTY = 0

            //-- Within brown rect - #bc8100
            //-- [font/Roves Sans Bold Rough.otf - #fff9e0 - 8vw / 8vw] YOUR BEAUTIFUL\NITALIAN NAME
            const style4 = new PIXI.TextStyle({
                fontFamily: 'birraBase',
                fontWeight: "normal",
                fontSize: this.convVWtoPix(8,tw),
                lineHeight: this.convVWtoPix(8,tw),
                fill: 0xfff9e0,
                align: 'left',
                wordWrap: true,
                wordWrapWidth: twWrap
            });
            const text4 = "YOUR BEAUTIFUL\nITALIAN NAME"
            const tm4 = PIXI.TextMetrics.measureText(text4, style4);
            const t4 = new PIXI.Text(text4, style4);
            t4.x = lrM
            t4.y = 10*4
            bLayerT.addChild(t4)
            sTY = t4.y + tm4.height
        
            //-- [font/Gotham HTF Book.otf - #fff9e0 - 4vw / 6.6vw ] Text
            const style5 = new PIXI.TextStyle({
                fontFamily: 'gothamBook',
                fontWeight: "normal",
                fontSize: this.convVWtoPix(4,tw),
                lineHeight: this.convVWtoPix(6.6,tw),
                fill: 0xfff9e0,
                align: 'left',
                wordWrap: true,
                wordWrapWidth: twWrap
            });
            const text5 = "" + nameText
            const tm5 = PIXI.TextMetrics.measureText(text5, style5);
            const t5 = new PIXI.Text(text5, style5);
            t5.x = lrM
            t5.y = sTY + 10*4
            bLayerT.addChild(t5)
            sTY = t5.y + tm5.height + 10*4

            //-- place the rect beneath the above text, we know the height now!
//            const bmMM = new PIXI.Graphics();
            const brect = new PIXI.Graphics();
            brect.beginFill(0xbc8100);
            brect.drawRect(tw/2, 0, tw/2, sTY);
            brect.endFill();
            bLayer.addChild(brect)
            
            const brect2 = new PIXI.Graphics();
            brect2.beginFill(0xbc8100);
            brect2.drawRoundedRect(0, 0, tw, sTY, 25*2);
            brect2.endFill();
            bLayer.addChild(brect2)


            //-- yellow text part

            nY += sTY 
            //-- [font/Roves Sans Bold Rough.otf - #4f2c1d - 8vw / 8vw] CELEBRATE YOUR BIRTHDAY THE ITALIAN WAY
            const style6 = new PIXI.TextStyle({
                fontFamily: 'birraBase',
                fontWeight: "normal",
                fontSize: this.convVWtoPix(8,tw),
                lineHeight: this.convVWtoPix(8,tw),
                fill: 0x4f2c1d,
                align: 'left',
                wordWrap: true,
                wordWrapWidth: twWrap
            });
            const text6 = "CELEBRATE YOUR BIRTHDAY THE ITALIAN WAY"
            const tm6 = PIXI.TextMetrics.measureText(text6, style6);
            const t6 = new PIXI.Text(text6, style6);
            t6.x = lrM
            t6.y = nY + 10*4
            tLayer.addChild(t6)
            nY = t6.y + tm6.height

            //-- [font/Gotham HTF Book.otf - #4f2c1d - 4vw / 6.6 vw] - text
            const style7 = new PIXI.TextStyle({
                fontFamily: 'gothamBook',
                fontWeight: "normal",
                fontSize: this.convVWtoPix(4,tw),
                lineHeight: this.convVWtoPix(6.6,tw),
                fill: 0x4f2c1d,
                align: 'left',
                wordWrap: true,
                wordWrapWidth: twWrap
            });
            const text7 = "" + bdText
            const tm7 = PIXI.TextMetrics.measureText(text7, style7);
            const t7 = new PIXI.Text(text7, style7);
            t7.x = lrM
            t7.y = nY + 10*4
            tLayer.addChild(t7)
            nY = t7.y + t7.height
        }
        else
        {
            nY += 40;

            const bLayer = new PIXI.Container();
            const bLayerT = new PIXI.Container();
            const bLayerEnd = new PIXI.Container();

            bLayerEnd.addChild( bLayer )
            bLayerEnd.addChild( bLayerT )

            tLayer.addChild(bLayerEnd)
            bLayerEnd.y = nY

            let sTY = 0

            //-- Within brown rect - #bc8100
            //-- [font/Roves Sans Bold Rough.otf - #fff9e0 - 8vw / 8vw] YOUR BEAUTIFUL\NITALIAN NAME
            const style4 = new PIXI.TextStyle({
                fontFamily: 'birraBase',
                fontWeight: "normal",
                fontSize: this.convVWtoPix(8,tw),
                lineHeight: this.convVWtoPix(8,tw),
                fill: 0xfff9e0,
                align: 'left',
                wordWrap: true,
                wordWrapWidth: twWrap
            });
            const text4 = "YOUR BEAUTIFUL\nITALIAN NAME"
            const tm4 = PIXI.TextMetrics.measureText(text4, style4);
            const t4 = new PIXI.Text(text4, style4);
            t4.x = lrM
            t4.y = 10*4
            bLayerT.addChild(t4)
            sTY = t4.y + tm4.height
        
            //-- [font/Gotham HTF Book.otf - #fff9e0 - 4vw / 6.6vw ] Text
            const style5 = new PIXI.TextStyle({
                fontFamily: 'gothamBook',
                fontWeight: "normal",
                fontSize: this.convVWtoPix(4,tw),
                lineHeight: this.convVWtoPix(6.6,tw),
                fill: 0xfff9e0,
                align: 'left',
                wordWrap: true,
                wordWrapWidth: twWrap
            });
            const text5 = "" + nameText
            const tm5 = PIXI.TextMetrics.measureText(text5, style5);
            const t5 = new PIXI.Text(text5, style5);
            t5.x = lrM
            t5.y = sTY + 10*4
            bLayerT.addChild(t5)
            sTY = t5.y + tm5.height + 10*4

            //-- place the rect beneath the above text, we know the height now!
//            const bmMM = new PIXI.Graphics();
            const brect = new PIXI.Graphics();
            brect.beginFill(0xbc8100);
            brect.drawRect(tw/2, 0, tw/2, sTY);
            brect.endFill();
            bLayer.addChild(brect)
            
            const brect2 = new PIXI.Graphics();
            brect2.beginFill(0xbc8100);
            brect2.drawRoundedRect(0, 0, tw, sTY, 25*2);
            brect2.endFill();
            bLayer.addChild(brect2)

            nY += sTY 
        }

        //--------------------------------


        //-- save last height for cropping!
        cont.addChild(tLayer)
        let endHeight = tLayer.y + nY + 40 + 20

        //-- draw linie for feedback
        if( 0 === 1 ) {
            const tLine = new PIXI.Graphics();
            tLine.beginFill(0xff00ff);
            tLine.drawRect(0, endHeight, tw, 2 );
            tLine.endFill();
            cont.addChild(tLine)
        }

        console.log("tLayer height:", nY, compH, endHeight)

        //-- wait for at least 1 render!
//        await new Promise(r => setTimeout(r, 100));
        await new Promise(r => requestAnimationFrame(r));

        const canvas = tapp.view;
        const sImgData = canvas.toDataURL()
//        return sImgData;

        //-- copy data over to fitted canvas and get image data
        const sImg = await this.loadImage( sImgData );
        const dCanv = document.createElement('canvas')
        dCanv.width = compW
        dCanv.height = endHeight
        const dCCont = dCanv.getContext('2d')
        dCCont.drawImage(sImg,0,0);

        return dCanv.toDataURL()
    }

    //---------------------------------------------------------------------------------------




    render() {
        let mstyle = {}
        if(this.props.zIndex)mstyle.zIndex=this.props.zIndex
        return (
            <>
                <div className={this.props.className} ref={this.cRef} style={mstyle}></div>
            </>
        )
    }
}

export default ManipulationSceneEndLabel9x16;