/*
    
    https://github.com/TJCoding/Enhanced-Image-Colour-Transfer-2
    
*/
import React from 'react';
import { gsap } from "gsap/all";
import * as PIXI from 'pixi.js-legacy'
//import hotkeys from 'hotkeys-js';
import { isMobile } from 'mobile-device-detect';
import { loadApi } from '../store/preloaderstore'
import { appApi } from '../store/appstore'

//import {FXAAFilter} from  './../filters/filter-fxaa';
import './ManipulationSceneCamera.css';

let isIOS = (/iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) && !window.MSStream
let isSafari = /^((?!chrome|android).)*safari/i.test(window.navigator.userAgent);


const ls = require('local-storage');

class ManipulationSceneCameraLabel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showGrid:true,
        };

        this.cRef = React.createRef();

        this.PConfig = {
            forceCanvas:true,
            backgroundAlpha:0,
            resolution: 1,
            antialias:true,
            autoResize:false,
            autoDensity: false,
            width:this.props.canvwidth,
            height:this.props.canvheight,
        }
        console.log("mobile config: ", this.PConfig)

        if(0 === 1 ) {
            this.PConfig = {
                backgroundAlpha:0,
                resolution: 1,
                antialias:true,
                autoResize:true,
                autoDensity: true,
                resizeTo:window,
            }
            console.log("desktop config")
        }

        this.app = new PIXI.Application(this.PConfig);

        //-- global ref!
//        window._PIXI_APP = this.app
        
        this.centerP1 = {x:0,y:0}

        this._handleResize = this.handleResize.bind(this)
        this._handleResize2 = this.handleResize2.bind(this)

        this.iLayer = null;

        this.myPic = null;

        this.bmManWHRatio = 1;// 0.94765343

        this.camTexture = null
        this.videoCont = null;

        this.man = null;

        //-- man offset center face
        this.manAncX = 0.54472;
        this.manAncY = 0.3174;
        
        this.labelYOffset = 50;

        this.calculatedYOffset = 96 + this.labelYOffset;

        this._YEAR = "1985"

        this.isMobile = isMobile
    }

    componentDidMount() {
        console.log("ManipulationSceneCamera -> componentDidMount")

        this.renderMultiplier = this.props.renderMultiplier === undefined ? 1 : this.props.renderMultiplier
        
        try {
            this.createLabelComposition( this.props.canvwidth * this.renderMultiplier,this.props.canvheight * this.renderMultiplier, this.app.stage, this.props.renderBottle )
            this.cRef.current.appendChild(this.app.view)
        } catch(err){ console.error(err)}

        if(this.isMobile) {
            window.addEventListener('orientationchange', this._handleResize2);
        } else {
            window.addEventListener('resize', this._handleResize)
        }

        //-- do skintone!
        this.checkSkinTone();

        if(this.props.componentReady){
            console.log("calling this.props.componentReady")
            this.props.componentReady(loadApi.getState().manposition2)
        }
    }
    componentWillUnmount() {
        if(this.isMobile) {
            window.removeEventListener('orientationchange', this._handleResize2);
        } else {
            window.removeEventListener('resize', this._handleResize)
        }
        if(this.app) {
            this.cRef.current.removeChild(this.app.view)
        }

        if(this.unsub1)this.unsub1()
    }

    handleResize(e) {
    };
    handleResize2(e) {
    }

    getPictureData() {
        const imageUrl = this.app.renderer.plugins.extract.base64();
    }

    //--
    //--    createFromBase64
    //--
    createFromBase64(photo) {

//        let manO = loadApi.getState().manposition2

        var image = new Image();
        image.src = photo
        image.onload = () => {
            
            this.photo.removeChildren()
            
            const base = new PIXI.BaseTexture(image);
            const texture = new PIXI.Texture(base);
            console.log(window.screen.width, " | ", base.width,base.height,texture.width,texture.height)
            
            this.myPic = texture
            
            let tw = texture.width,
                th = texture.height,
                ratio2 = window.screen.width / tw
            
            let myPic = new PIXI.Sprite(texture);
            myPic.width = tw
            myPic.height = th
            myPic.scale.x *= -1;
            myPic.x = tw/2
            myPic.y = -th/2
            
            console.log("myPic.width:",myPic.width," myPic.height:",myPic.height)
            //-- scale and set correct position
            let picScaledCont = new PIXI.Container()
            picScaledCont.addChild(myPic)
            //-- apply scaling factors, as in createCameraTexture
            picScaledCont.scale.x = picScaledCont.scale.y = ratio2 * loadApi.getState().cameraScaleFactor

            console.log("picScaledCont.width:",picScaledCont.width," picScaledCont.height:",picScaledCont.height)

            this.photo.addChild( picScaledCont )

            if(this.props.loadingFaceReady) {
                gsap.delayedCall(0.1,()=>{
                    this.props.loadingFaceReady()
                })
            }
        }
    }

    //--
    //--    setYear
    //--
    setYear(y) {
        this._YEAR = y;
        if(this.txtYear) {
            console.log("setYear:",y)
            this.txtYear.text = "DAL " + y
        }
    }

    //--
    //--    createLabelComposition
    //--
    createLabelComposition(stageW,stageH,renderDest,renderBottle) {
        let manO = null, 
            screenW = stageW, 
            screenH = stageH, 
            w2 = screenW/2, 
            h2 = screenH/2,
            lapi = loadApi.getState(),
            manExtraSW = lapi.manExtraWidthRelWS

        this.finalLayer = new PIXI.Container();
        this.finalLabelLayer = new PIXI.Container();
        if(renderBottle === 1 ) {
//            this.finalLabelLayer.filters = [FXAAFilter]
        }
        this.iLayer = new PIXI.Container();
        var oLay = new PIXI.Container();
        let oLayMask = new PIXI.Container();
        this.photo = new PIXI.Container();
        this.iLayer.addChild( this.photo );
        this.mLayer = new PIXI.Container();
        this.textLayer = new PIXI.Container();

        //-- RENDER BOTTLE AS BACKGROUND!
//        this.finalLabelLayer.anchor.set(0.5, 0.5);
/*
        if(renderBottle === 1 ) {
            let bigBottle = PIXI.Sprite.from(PIXI.Loader.shared.resources.bigBottle.texture)
            bigBottle.anchor.set(0.4192, 0);
            bigBottle.x = screenW / 2
            this.finalLayer.addChild( bigBottle )
        }
*/
        //-- RENDER BG LABEL
        let bmLabelBG = PIXI.Sprite.from(PIXI.Loader.shared.resources.bmLabelBG.texture)
        bmLabelBG.anchor.set(0.5, 0.5);
//        bmLabelBG.filters = [FXAAFilter]

//        this.textRadiusMax = (screenW - 20) * 0.81
        if( this.isMobile ) 
        {
            let labScale = (screenW - 20) / bmLabelBG.width
            bmLabelBG.scale.set( labScale, labScale )
            bmLabelBG.x = w2
            bmLabelBG.y = (w2) + this.labelYOffset
            
            this.labScale = labScale

            this.textLayer.x = bmLabelBG.x
            this.textLayer.y = bmLabelBG.y
        }
        this.finalLabelLayer.addChild( bmLabelBG )

        //-- RENDER MAN
        let bmMan = PIXI.Sprite.from(PIXI.Loader.shared.resources.bmMan3.texture)
        bmMan.anchor.set(this.manAncX, this.manAncY);
//            bmMan.alpha = 0.4
        bmMan.x = bmLabelBG.x
//        bmMan.y = bmLabelBG.y - (bmLabelBG.width/8)
        bmMan.y = bmLabelBG.y - (bmLabelBG.width/5)
//        bmMan.filters = [FXAAFilter]
        this.iLayer.x = bmMan.x
        this.iLayer.y = bmMan.y
        this.mLayer.x = bmMan.x
        this.mLayer.y = bmMan.y

        let bmanOW = bmMan.width,
            bmanOH = bmMan.height,
            bmanFact = bmanOW / bmanOH,
            bmanBW = bmLabelBG.width/1.8,
            bmanBH = bmanOH * (bmanBW/bmanOW),
            diffScaleFact = 1;

        //-- scale man
        if( 1 === 1 ) 
        {
            bmMan.width = bmanBW
            bmMan.height = bmanBH

//            bmanSFW =  bmMan.width / bmanBW 
//            bmanSFH =  bmMan.height / bmanBH 
//            console.log("bmanBW:",bmanBW,"bmanBH:",bmanBH," bmanSFW:",bmanSFW,"bmanSFH:",bmanSFH)
//            console.log("man scale:",bmMan.scale.x,bmMan.scale.y)
            
            loadApi.getState().manScaleFact1 = (screenW + manExtraSW) / screenW
            loadApi.getState().manScaleFact2 = bmMan.width / screenW
            diffScaleFact = loadApi.getState().manScaleFact1 - loadApi.getState().manScaleFact2
            loadApi.getState().diffScaleFact = diffScaleFact
            console.log("manScaleFact1:",loadApi.getState().manScaleFact1,"manScaleFact2:",loadApi.getState().manScaleFact2, " diffScaleFact:",diffScaleFact)

            //-- calculate the top of man
            this.manLeftOC = bmMan.width * this.manAncX
            this.manTopOC = bmMan.height * this.manAncY
            this.manRightOC = bmMan.width - this.manLeftOC
            this.manBottomOC = bmMan.height - this.manTopOC

//                console.log("this.manLeft:",this.manLeftOC," this.manTop:",this.manTopOC," this.manRight:",this.manRightOC," this.manBottom:",this.manBottomOC)

            this.MLeft = w2 - this.manLeftOC;
            this.MTop = -h2 + this.manTopOC;
            this.MRight = w2 + this.manRightOC;
            this.MBottom = h2 + this.manBottomOC;

//                console.log("this.MLeft:",this.MLeft," this.MTop:",this.MTop," this.MRight:",this.MRight," this.MBottom:",this.MBottom)

            this.htmlMLeft = this.MLeft;
            this.htmlMTop = (h2 - this.manTopOC) + this.MTop + this.calculatedYOffset;
            this.htmlMRight = this.MRight;
            this.htmlMBottom = (-h2 + this.manTopOC) + this.MBottom + this.calculatedYOffset;

            console.log("this.htmlMLeft:",this.htmlMLeft," this.htmlMTop:",this.htmlMTop," this.htmlMRight:",this.htmlMRight," this.htmlMBottom:",this.htmlMBottom)
        }
        oLay.addChild( bmMan )
        
        this.man = bmMan;
        
//            bmMan.filters = [ filter ];
        manO = {left:this.htmlMLeft,right:this.htmlMRight,top:this.htmlMTop,bottom:this.htmlMBottom,x:bmMan.x,y:bmMan.y,w:bmMan.width,h:bmMan.height,scaleF:bmMan.width/screenW,olx:oLay.x,oly:oLay.y}

        const bmMM = new PIXI.Graphics();
        bmMM.lineStyle(0);
        bmMM.beginFill(0xff0000);
        bmMM.lineTo(0,230)
        bmMM.lineTo(867,230)
        bmMM.lineTo(1038,317)
        bmMM.lineTo(1365,298)
        bmMM.lineTo(1365,651)
        bmMM.lineTo(1281,686)
        bmMM.lineTo(704,727)
        bmMM.lineTo(506,617)
        bmMM.lineTo(0,914)
        bmMM.scale.set(bmMan.scale.x) 
        bmMM.x = -(bmMan.width*this.manAncX) + bmMan.x
        bmMM.y = -(bmMan.height*this.manAncY)   //-- use height if bmMan!
        oLayMask.addChild(bmMM)
        oLayMask.y += bmMan.y


        let bmMoustache = null;
        bmMoustache = PIXI.Sprite.from(PIXI.Loader.shared.resources.moustache.texture)
        bmMoustache.anchor.set(0.5, 0.5);
        bmMoustache.scale.set(0.3)
        bmMoustache.x = 0
        bmMoustache.y = 0

        this.mLayer.addChild( bmMoustache )

        //-- add Name to it!
        let contName = new PIXI.Container();
        //-- add NAME
        let textStyleName = new PIXI.TextStyle({
                            fontFamily: 'birra-body',
                            fontWeight: "normal",
//                            fontSize: bmMan.scale.x*320,
                            fontSize: bmMan.scale.x*400,
                            fill: 0x936c21,
                            align: 'center',
                        });
        let txtName = new PIXI.Text(this._name, textStyleName);
        txtName.anchor.set(0.5, 0.5);
        this.txtName = txtName
        contName.addChild(txtName)
        contName.x = w2
        contName.y = bmMan.y + (bmMan.scale.x * (1070+60))
        this.finalLabelLayer.addChild( contName )
        
        //-- add Year of birth to it!
        let contDal = new PIXI.Container();
        let dal = null;
        dal = PIXI.Sprite.from(PIXI.Loader.shared.resources.dalDash.texture)
        dal.anchor.set(0.5, 0.5);
//        dal.scale.set(bmMan.scale.x*2.4)
        dal.scale.set(bmMan.scale.x*3.1)
        contDal.addChild( dal )
        //-- add YEAR
        let textStyle = new PIXI.TextStyle({
            //                fontFamily: 'Arial',
                            fontFamily: 'birra-body',
                            fontWeight: "normal",
//                            fontSize: bmMan.scale.x*180,
                            fontSize: bmMan.scale.x*220,
                            fill: 0x936c21,
                            align: 'center',
                        });
        let txtYear = new PIXI.Text("DAL " + this._YEAR, textStyle);
        txtYear.anchor.set(0.5, 0.5);
        this.txtYear = txtYear
        contDal.addChild(this.txtYear)
        contDal.x = w2
        contDal.y = bmMan.y + (bmMan.scale.x * (1070 + 350))
        this.finalLabelLayer.addChild( contDal )
        
        
        manO.bottom = bmLabelBG.y + (bmLabelBG.height /2) + this.labelYOffset + 20
        
        //-- place head on position
//        this.iLayer.y += manO.y
//        this.mLayer.y += manO.y
        
        //-- add to finalLabelLayer
        this.finalLabelLayer.addChild( this.iLayer )
        this.finalLabelLayer.addChild( this.mLayer )
        this.finalLabelLayer.addChild( oLay )
        this.finalLabelLayer.addChild( oLayMask )
        this.finalLabelLayer.addChild( this.textLayer )
        this.photo.mask = oLayMask
        
        //-- add to final layer
        this.finalLayer.addChild( this.finalLabelLayer )
        

        //-- make label fit to bottle 
        if(renderBottle === 1 ) {
            this.setNameText("NAME")

            let scaleFact = 1.2;
            //-- position bottle center and position label on bottle
            let endScale = 0.65 * scaleFact, cpix = screenW * ( (1 - endScale) / 2) 
            this.finalLayer.scale.set( endScale, endScale ) 
            this.finalLayer.y += 20;
            this.finalLayer.x += cpix

            //-- position label on bottle
            let labEndS = 0.75 * scaleFact, labcpix = (this.finalLabelLayer.width * ( (1 - labEndS) / 2))
            this.finalLabelLayer.scale.set( labEndS, labEndS )
            this.finalLabelLayer.x += labcpix;
            this.finalLabelLayer.y += 360;
        }

        //-- due to 50 pixels extra with in take picture scene, we need to correct this by scaling more down
        //-- so we calculate the extra scale factor here! manExtraSW
        let scaleCorrTPWS = 1.0 - (manExtraSW / screenW)

        //--  FACE!

        let newScale = lapi.imgScale
        if(!newScale)newScale = ls.get("headScale")
        if( newScale ) {
            console.log("FACE scale:",newScale,scaleCorrTPWS)
            //-- scale is based on other dimensions
            this.iLayer.scale.set( newScale * manO.scaleF * scaleCorrTPWS);
        } else {
            console.log("NO FACE scale:")
            this.iLayer.scale.set( manO.scaleF * scaleCorrTPWS )
        }

        let rad = lapi.imgRot
        if(!rad)rad = ls.get("headRot")
        if(rad) this.iLayer.rotation = rad;

        let imgPos = lapi.imgPos
        if(!imgPos)imgPos = ls.get("headPos")
        if( imgPos ) {
            this.iLayer.x += imgPos.dx * manO.scaleF * scaleCorrTPWS
            this.iLayer.y += imgPos.dy * manO.scaleF * scaleCorrTPWS
        }
        
//        console.log("Head scale:",newScale," rotation:",rad," pos:",imgPos)

        //-- MOUSTAGE
        
        newScale = lapi.moustacheScale
        if(!newScale)newScale = ls.get("moustacheScale")
        if( newScale ) {
            this.mLayer.scale.set( newScale * manO.scaleF * scaleCorrTPWS );
        } else {
            this.mLayer.scale.set( manO.scaleF * scaleCorrTPWS );
        }
        
        rad = lapi.moustacheRot
        if(!rad)rad = ls.get("moustacheRot")
        if(rad) this.mLayer.rotation = rad;
        
        imgPos = lapi.moustachePos
        if(!imgPos)imgPos = ls.get("moustachePos")
        if( imgPos ) {
            this.mLayer.x += (imgPos.dx * manO.scaleF * scaleCorrTPWS)
            this.mLayer.y += (imgPos.dy * manO.scaleF * scaleCorrTPWS)
        } else {
            this.mLayer.x += -(20 * manO.scaleF * scaleCorrTPWS)
            this.mLayer.y += (20 * manO.scaleF * scaleCorrTPWS)
        }

        console.log("Moustage scale:",newScale," rotation:",rad," pos:",imgPos)

        loadApi.getState().setManPos2(manO)

        //-- add everything to stage
        renderDest.addChild( this.finalLayer )

        return manO
    }


    checkSkinTone() {

        this.unsub1 = appApi.subscribe(skintone => {
            this.setSkinToneByNr(skintone)
        }, state => state.skintone)
        
        let skintone = appApi.getState().skintone
        this.setSkinToneByNr(skintone)
    }
    setSkinToneByNr(skintone) {
        if(this.man) {
            if( skintone === 1 ) {
                this.man.texture = PIXI.Loader.shared.resources.bmMan1.texture;
            }
            if( skintone === 2 ) {
                this.man.texture = PIXI.Loader.shared.resources.bmMan2.texture;
            }
            if( skintone === 3 ) {
                this.man.texture = PIXI.Loader.shared.resources.bmMan3.texture;
            }
            if( skintone === 4 ) {
                this.man.texture = PIXI.Loader.shared.resources.bmMan4.texture;
            }
            if( skintone === 5 ) {
                this.man.texture = PIXI.Loader.shared.resources.bmMan5.texture;
            }
            if( skintone === 6 ) {
                this.man.texture = PIXI.Loader.shared.resources.bmMan6.texture;
            }
        }
    }


    //--
    //--    createLabelImageBig
    //--
    createLabelImageBig() {
        if(!this.app) return
        
        let renderer = this.app.renderer,
            compW = 512, 
            compH = Math.floor(compW*1.2),
            renderTexture = PIXI.RenderTexture.create({width:compW, height:compH, resolution:1}),
            finalLayer = new PIXI.Container()
        
        this.renderBIGW = compW
        this.renderBIGH = compH

        try {
            this.createLabelComposition(compW,compH,finalLayer,0)
        } catch(err){ console.error(err)}

        renderer.render(finalLayer, {renderTexture});

        let canvas = renderer.extract.canvas(renderTexture);
//        let canv = renderTexture.getCanvas()
        return canvas.toDataURL()
    }

    //--
    //-- createShareComposition
    //--
    createShareComposition() {

    }

    getCompositionData() {
        if(!this.composition) return null;
        var canvas = this.composition.getCanvas();
        return canvas.toDataURL()
    }

    getFinalCompositionImage() {
        var canvas = this.app.view;
        return canvas.toDataURL()
    }
/*
    setNameCanvas(canv) {
        let texture = PIXI.Texture.from(canv);
        this.nameTextSprite = new PIXI.Sprite(texture)
        this.nameTextSprite.anchor.set(0.5, 0.5);
        this.textLayer.addChild(this.nameTextSprite)
//        this.app.stage.addChildAt(this.nameTextSprite)
    }
*/
    setNameText(text) {
        this._name = text
        if(this.txtName){
            this.txtName.text = text
        }
//        this.updateNameTextLayer(text)
    }
    updateNameTextLayer(text) {
        if(text) {

        }
    }

    setNameText2(text) {
        this._name = text
        this.updateNameTextLayer2(text)
    }
    updateNameTextLayer2(text) {

    }

    render() {
        let mstyle = {}
        if(this.props.zIndex)mstyle.zIndex=this.props.zIndex
        return (
            <>
                <div className={this.props.className} ref={this.cRef} style={mstyle}></div>
            </>
        )
    }
}

export default ManipulationSceneCameraLabel;