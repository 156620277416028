import React,{ useState, useEffect, useRef } from "react";
import {
  useNavigate,
  useLocation
} from "react-router-dom";
import { isMobile } from 'mobile-device-detect';
import { gsap } from "gsap/all";
//import { useLoadApi } from '../store/preloaderstore'
import Lottie from 'react-lottie-player'
import lottieJson from '../lotties/makeyoufit.json'
import VideoBackgroundCover from './../components/VideoBackgroundCover'

export default function InstructVideo2() {
  let navigate = useNavigate();

  const page = useRef(null);

  useEffect(() => {
    if(window.location.href.indexOf("lentfertnuc")>-1) gsap.delayedCall(0,()=>{ navigate("/editpicture") })

    if ('parentIFrame' in window) {
      const ph = gsap.getProperty(page.current,"height")
      window.parentIFrame.size( 690 );
      window.parentIFrame.scrollTo(0,0)
    }

  },[]);

  const adjustIframeHeight = () => {
    const ph = gsap.getProperty(page.current,"height")
    console.log(ph,window.innerHeight)
    if(window !== window.parent) gsap.set(window.parent,{"height":ph})
  }
  
  const lottieCompleted = () =>{
    gsap.delayedCall(0,()=>{
      navigate("/editpicture")
    })
  }

  const onEnterFrame = (fr) => {
//    console.log(fr)
    if(fr.currentTime > 144) {
      navigate("/editpicture")
    }
  }

  return (
    <div className="page" ref={page}>

      <Lottie
        loop={false}
        animationData={lottieJson}
        renderer="svg"
        play
        onEnterFrame={onEnterFrame}
//        onComplete={lottieCompleted}
        style={{ position:"absolute",width:"100%",  zIndex:20,  pointerEvents:"none" }}
      />

      <VideoBackgroundCover />

    </div>
  );
}
