import React from 'react';
import { gsap } from "gsap/all";
import { loadApi } from './../store/preloaderstore'
import { appApi } from './../store/appstore'

//import WaitingSpriteAnimation from './WaitingSpriteAnimation'
import WaitingSpriteAnimation2 from './WaitingSpriteAnimation2'

import './OverlayUploadServer.css';

class OverlayUploadServer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {show:false}
        this.elRef = React.createRef();
    }
    
    componentDidMount() {
      gsap.set(this.elRef.current,{display:"none"})
      
      this.unsub1 = loadApi.subscribe(showpreprocessing => {
        if(showpreprocessing)gsap.set(this.elRef.current,{display:"flex"})
        else gsap.set(this.elRef.current,{display:"none"})
        this.setState({show:showpreprocessing})
      }, state => state.showpreprocessing)

      this.unsub2 = loadApi.subscribe(uploaderror => {
        if(uploaderror !== 0 ) {
        }
      }, state => state.uploaderror)

      //-- check for errors, then hide this overlay
      this.unsub3 = appApi.subscribe(uploaderrormsg => {
        if(uploaderrormsg !== "") {
          gsap.set(this.elRef.current,{display:"none"})
          this.setState({show:false,error:uploaderrormsg})
        }
      }, state => state.uploaderrormsg)
    }
    componentWillUnmount() {
      this.unsub1()
      this.unsub2()
      this.unsub3()
    }
    
    render() {
        return (
          <div className="olUS" ref={this.elRef}>
              {this.state.show && <WaitingSpriteAnimation2 zIndex={2000} />}
          </div>
      )
    }
}

export default OverlayUploadServer;
