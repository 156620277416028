import React from 'react';
import { useLoadApi } from '../store/preloaderstore'
import { appApi } from './../store/appstore'
import { gsap } from "gsap/all";

import './BackgroundRoundedUnder.css';

class BackgroundRoundedUnder extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
        this.panel = React.createRef();
    }

    componentDidMount() {
        this.unsub1 = useLoadApi.subscribe(manposition => {
            console.log("   BackgroundRoundedUnder -> position")
            let manO = manposition
        }, state => state.manposition)
    }
    componentWillUnmount() {
        this.unsub1()
    }

    update() {
        let manO = useLoadApi.getState().manposition2
        if(!manO)manO = useLoadApi.getState().manposition
        if(manO) {
            let sy = manO.bottom-52, sh = window.screen.height - sy
            gsap.set(this.panel.current,{top:sy,height:sh});
        }
    }

    render() {
        return (
            <div className='panelUnder' ref={this.panel}></div>
        )
    }
}

export default BackgroundRoundedUnder;
