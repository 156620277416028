/*
    
    https://github.com/TJCoding/Enhanced-Image-Colour-Transfer-2
    
*/
import React from 'react';
import { gsap } from "gsap/all";
import * as PIXI from 'pixi.js-legacy'
//import hotkeys from 'hotkeys-js';
import { isMobile } from 'mobile-device-detect';
import { loadApi } from './../store/preloaderstore'
import { appApi } from './../store/appstore'

import { OutlineFilter } from 'pixi-filters';
import CustomCanvasCamera from "./../components/CustomCanvasCamera";

import './ManipulationSceneCamera.css';


const ls = require('local-storage');

class ManipulationSceneCamera extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        	numCameras:1,
        	currentFacingMode:"user",
        	videoSourceDeviceId :undefined,
        };

        this.cRef = React.createRef();
//        this.ccc = React.createRef();

        if( isMobile ) {
            this.PConfig = {
                forceCanvas:true,
                backgroundAlpha:0,
                resolution: 1,
                antialias:true,
                autoResize:false,
                autoDensity: false,
                width:window.screen.width,
                height:window.screen.height,
            }
        } else {
            this.PConfig = {
                backgroundAlpha:0,
                resolution: 1,
                antialias:true,
                autoResize:true,
                autoDensity: true,
                resizeTo:window,
            }
        }

        this.video = React.createRef();
        this.stream = null
        this.camSettings = null
		this.stdRatio = 1920 / 1080;

        this.app = new PIXI.Application(this.PConfig);
        
        this._noScroll = this.noScroll.bind(this)


        this.posP = null

        this._handleResize = this.handleResize.bind(this)
        this._handleResize2 = this.handleResize2.bind(this)

        this.iLayer = null;
        this.eLayer = null;

        this.bmManWHRatio = 1;// 0.94765343

        this.camTextureCreated = null
        this.videoCircleMask = null;

        this.man = null;
        this.manHands = null;
        this.manO = null;

        //-- man offset center face
        this.manAncX = 0.54472;
        this.manAncY = 0.3174;
        
        this.calculatedYOffset = 80;
    }

    async componentDidMount() {
        console.log("ManipulationSceneCamera -> componentDidMount")

        if(this.props.foto ) {
            loadApi.getState().imgRot = 0
            loadApi.getState().imgScale = 0
            loadApi.getState().imgPos = null
        
            loadApi.getState().moustacheRot = 0
            loadApi.getState().moustacheScale = 0
            loadApi.getState().moustachePos = null
        }

        try {
            this.createScaleRotateDragGrid()
            this.cRef.current.appendChild(this.app.view)

            if( this.props.autoTouch) {
                this.app.renderer.plugins.interaction.autoPreventDefault = false;
                this.app.view.style.touchAction = 'auto';
            }
        } catch(err){ console.error(err)}

        if(isMobile) {
            window.addEventListener('orientationchange', this._handleResize2);
        } else {
            window.addEventListener('resize', this._handleResize)
        }

        //-- do skintone!
        this.checkSkinTone();
//        this.setHeadImageOrMoustagePosIfNotSet()

        this._vPlaying = this.vidEVPlaying.bind(this)
        this._vError = this.vidEVError.bind(this)

        this.video.current.setAttribute("muted","");
        this.video.current.setAttribute("webkit-playsinline", "");
        this.video.current.setAttribute("playsinline", "");
        this.video.current.setAttribute("preload", "auto");

        this.video.current.addEventListener("playing", this._vPlaying);
        this.video.current.addEventListener("error", this._vError);

        if( this.props.foto ) {
            gsap.delayedCall(0.5, async ()=>{
                await this.initCameraStream()
            })
        }

        appApi.getState().setComponentReady()
        
        if(this.props.componentReady){
            console.log("calling this.props.componentReady")
            this.props.componentReady(loadApi.getState().manposition)
        }
    }
    componentWillUnmount() {

		this.video.current.removeEventListener("playing", this._vPlaying);
		this.video.current.removeEventListener("error", this._vError);
		this._vPlaying = null;
		this._vError = null;

        this.video.current.srcObject.getVideoTracks().forEach(track => {
            track.stop()
            this.video.current.srcObject.removeTrack(track);
        });

        if(this.camSettings)this.camSettings = null

        if(isMobile) {
            window.removeEventListener('orientationchange', this._handleResize2);
        } else {
            window.removeEventListener('resize', this._handleResize)
        }
        if(this.app) {
            this.cRef.current.removeChild(this.app.view)
        }

        if(this.unsub1)this.unsub1()
    }

    handleResize(e) {
//        api.getState().setScaleData(obj);
    };
    handleResize2(e) {
    }

    //---------------------------------------------------------------------
    //--
    //--    video camera related
    //--

    vidEVPlaying(ev) {
		console.log(ev)
//		if(this.props.onSuccess)this.props.onSuccess(this.camSettings)
        this.cameraReady(this.camSettings)
	}
	vidEVError(ev) {
		window.alert(ev)
	}

	addListenerMulti(el, s, fn) {
		s.split(' ').forEach(e => el.addEventListener(e, fn, false));
	}
	vPlay(ev) {
		console.log(ev)
	}

    //--
    //--	initCameraStream
    //--
	async initCameraStream() {
	  // stop any active streams in the window
	  if (this.stream) {
		this.stream.getTracks().forEach(track => {
			track.stop();
	    });
	  }

	  const constraints = {
	    audio: false,
	    video: {
			deviceId: this.state.videoSourceDeviceId ? { exact: this.state.videoSourceDeviceId } : undefined,
			facingMode: this.state.currentFacingMode,
//			width: { ideal: 1920 },
//			height: { ideal: 1920 },
			width: { ideal: 960 },
			height: { ideal: 960 },
	    },
	  };

	  if (navigator && navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
//		console.log(" 1 collecting mediaDevices via navigator")
        if( 0 === 1 ) {
            let devices = await navigator.mediaDevices.enumerateDevices()
            devices.forEach((device) => {
                console.log(`${device.kind}: ${device.label} id = ${device.deviceId}`);
            });
        }

//		console.log(" 2 collecting mediaDevices via navigator")
		try {
			navigator.mediaDevices.getUserMedia(constraints)
				.then( stream => {
					this.stream = this.handleSuccess(stream)
				})
				.catch(err => {
					this.handleError(err);
				});
		} catch (err) {
			console.error(err)
		}
	  } else {
	    const getWebcam = 
			navigator.getUserMedia || 
			navigator.webkitGetUserMedia || 
			navigator.mozGetUserMedia || 
			navigator.mozGetUserMedia || 
			navigator.msGetUserMedia;

	    if (getWebcam) {
			getWebcam(
				constraints,
				stream => {
					this.stream = this.handleSuccess(stream)
				},
				err => {
					this.handleError(err);
				},
			);
	    } else {
			if(this.props.onNotSupported)this.props.onNotSupported(true);
	    }
	  }
	}
	
	async handleSuccess(stream) {

		console.log("we have a cam stream!")

        if( 0 === 1 ) {
            navigator.mediaDevices
                .enumerateDevices()
                .then(r => this.setNumberOfCameras(r.filter(i => i.kind === 'videoinput').length));
        }

		let camSettings = null
		this.video.current.srcObject = stream;
//		this.video.current.load();
//		this.video.current.play();
		await new Promise(resolve => this.video.current.onloadedmetadata = resolve);
//		console.log("videoWidth:",this.video.current.videoWidth,"videoHeight:",this.video.current.videoHeight);

		stream.getTracks().forEach(track => {
			camSettings = track.getSettings();
//			console.log(camSettings)
	    })
	    this.camSettings = camSettings;
		//-- add the aspect ratio as property, we need this later on when creating video texture!
		if( !this.camSettings.hasOwnProperty("aspectRatio")) {
			this.camSettings.aspectRatio = this.camSettings.width / this.camSettings.height
		}
		
		///-- not here, wait untill stream is playing!
//		if(this.props.onSuccess)this.props.onSuccess(camSettings)

		return stream;
	}

	handleError(error) {
		console.error(error);
		
		this.stream = null

		//https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia
		if (error.name === 'PermissionDeniedError') {
//			if(this.props.onPermissionDenied)this.props.onPermissionDenied(error.name,error.message);
            this.onPermissionDenied(error.name,error.message);
		} else {
//			if(this.props.onNotSupported)this.props.onNotSupported(error.name,error.message);
            this.onNotSupported(error.name,error.message);
		}
	}
	
	setNumberOfCameras(nc) {
		this.setState({numCameras:nc})
		console.log("numCameras:",nc)
//		if(this.props.onNumCameras)this.props.onNumCameras(nc)
//        this.onNumCameras(nc)
	}

	scaleImage(img,w,h) {
		return new Promise((resolve, reject) => {
		  	img.onload = () => {
				//here you all code goes

				resolve(true)
		  	}
		})
	}

	getVideoRef() {
		return this.video.current
	}

	takePhoto() {
		var canvas = document.createElement('canvas');
		
		canvas.id = "CursorLayer";
		canvas.width = this.camSettings.width;
		canvas.height = this.camSettings.height;
		
		const playerWidth = this.camSettings.width || 1280;
		const playerHeight = this.camSettings.height || 720;
		const playerAR = playerWidth / playerHeight;
		
		const canvasWidth = this.camSettings.width || 1280;
		const canvasHeight = this.camSettings.height || 1280;
		const canvasAR = canvasWidth / canvasHeight;
		
		let sX, sY, sW, sH;
		
		if (playerAR > canvasAR) {
			sH = playerHeight;
			sW = playerHeight * canvasAR;
			sX = (playerWidth - sW) / 2;
			sY = 0;
		} else {
			sW = playerWidth;
			sH = playerWidth / canvasAR;
			sX = 0;
			sY = (playerHeight - sH) / 2;
		}
		
		const context = canvas.getContext('2d');
		if (context && this.video.current) {
			context.drawImage(this.video.current, sX, sY, sW, sH, 0, 0, sW, sH);
		}

		const imgData = canvas.toDataURL('image/jpeg',0.75);

//        window.alert("w:"+canvas.width+" h:"+canvas.height+" sX:"+sX+" sY:"+sY+" sW:"+sW+" sH:"+sH)

        return imgData;
	}
	
    //---------------------------------------------------------------------

    //--
    //--    createScaleRotateDragGrid
    //--
    createScaleRotateDragGrid() {
        let manO = null, 
            screenW = this.app.renderer.screen.width, 
            screenH = this.app.renderer.screen.height,
            yoff = 0, 
            w2 = screenW/2, 
            h2 = screenH/2

//        console.log("sw:",screenW,window.screen.width)

        if(this.props.yoffset)yoff = parseFloat(this.props.yoffset)

        this.iLayer = new PIXI.Container();
        this.iLayer.position.set( w2, h2 + yoff )

        var oLay = new PIXI.Container();
        oLay.position.set( w2, h2 + yoff )
        
        let oLayMask = new PIXI.Container();
        oLayMask.x = oLay.x
        oLayMask.y = oLay.y

        this.eLayer = new PIXI.Container();
        this.eLayer.x = this.iLayer.x
        this.eLayer.y = this.iLayer.y

        this.photo = new PIXI.Container();
        this.iLayer.addChild( this.photo )

        let bmMan = null

        if( this.props.foto ) {
            bmMan = PIXI.Sprite.from(PIXI.Loader.shared.resources.bmMan3.texture)
            bmMan.anchor.set(this.manAncX, this.manAncY);

            bmMan.x = 0

            let bmanOW = bmMan.width,
                bmanOH = bmMan.height,
                bmanFact = bmanOW / bmanOH,
                manExtraSW = loadApi.getState().manExtraWidthRelWS

//            bmMan.alpha = 0.4

            let winW = screenW;
            //-- calculate man with depending on screen size and height!
            console.log("is mobile!")
            bmMan.width = screenW + manExtraSW
//                bmMan.height = bmMan.width
            bmMan.height = bmanOH * (bmMan.width/bmanOW)
            
            this.manScaleF = (bmMan.width/bmanOW)

//                loadApi.getState().manScaleFact1 = screenW / bmMan.width
            loadApi.getState().manScaleFact1 = (screenW + manExtraSW) / screenW
            console.log("manScaleFact1:",loadApi.getState().manScaleFact1)
//                console.log("man scale:",bmMan.scale.x,bmMan.scale.y)

            winW = screenW

            //-- calculate the top of man
            this.manLeftOC = bmMan.width * this.manAncX
            this.manTopOC = bmMan.height * this.manAncY
            this.manRightOC = bmMan.width - this.manLeftOC
            this.manBottomOC = bmMan.height - this.manTopOC

//                console.log("this.manLeft:",this.manLeftOC," this.manTop:",this.manTopOC," this.manRight:",this.manRightOC," this.manBottom:",this.manBottomOC)

            this.MLeft = w2 - this.manLeftOC;
            this.MTop = -h2 + this.manTopOC;
            this.MRight = w2 + this.manRightOC;
            this.MBottom = h2 + this.manBottomOC;

//                console.log("this.MLeft:",this.MLeft," this.MTop:",this.MTop," this.MRight:",this.MRight," this.MBottom:",this.MBottom)

            bmMan.y = this.MTop + this.calculatedYOffset;

            this.htmlMLeft = this.MLeft;
            this.htmlMTop = (h2 - this.manTopOC) + this.MTop + this.calculatedYOffset;
            this.htmlMRight = this.MRight;
            this.htmlMBottom = (-h2 + this.manTopOC) + this.MBottom + this.calculatedYOffset;

//                console.log("screenW:",screenW," screenH:",screenH)
//                console.log("manW:",bmMan.width," manH:",bmMan.height)

//            console.log("this.htmlMLeft:",this.htmlMLeft," this.htmlMTop:",this.htmlMTop," this.htmlMRight:",this.htmlMRight," this.htmlMBottom:",this.htmlMBottom)

            this.man = bmMan;

//            bmMan.filters = [ filter ];
            const scaleF = bmMan.width / winW
            manO = {left:this.htmlMLeft,right:this.htmlMRight,top:this.htmlMTop,bottom:this.htmlMBottom,x:bmMan.x,y:bmMan.y,w:bmMan.width,h:bmMan.height,scaleF:scaleF,olx:oLay.x,oly:oLay.y}
            loadApi.getState().setManPos(manO)
            this.manO = JSON.parse(JSON.stringify(manO));
            console.log("this.manO - 1 :", this.manO)
        }

        if( !this.props.foto ) {
            const bmMM = new PIXI.Graphics();
            bmMM.lineStyle(0);
            bmMM.beginFill(0xff0000);
            bmMM.lineTo(0,230)
            bmMM.lineTo(867,230)
            bmMM.lineTo(1038,317)
            bmMM.lineTo(1365,298)
            bmMM.lineTo(1365,651)
            bmMM.lineTo(1281,686)
            bmMM.lineTo(704,727)
            bmMM.lineTo(506,617)
            bmMM.lineTo(0,914)
            bmMM.scale.set(bmMan.scale.x) 
            bmMM.x = -(bmMan.width*this.manAncX)
            bmMM.y = -(bmMan.height*this.manAncY)   //-- use height if bmMan!
            oLayMask.addChild(bmMM)
            oLayMask.y += bmMan.y
        }
        
        if(this.props.foto ) {
            oLay.addChild( bmMan )
        }

        this.app.stage.addChild( this.iLayer )
        this.app.stage.addChild( oLay )

        return manO
    }

    checkSkinTone() {
        this.unsub1 = appApi.subscribe(skintone => {
            this.setSkinToneByNr(skintone)
        }, state => state.skintone)
        
        let skintone = appApi.getState().skintone
        this.setSkinToneByNr(skintone)
    }
    setSkinToneByNr(skintone) {
        if(this.man) {
            if( skintone === 1 ) {
                this.man.texture = PIXI.Loader.shared.resources.bmMan1.texture;
            }
            if( skintone === 2 ) {
                this.man.texture = PIXI.Loader.shared.resources.bmMan2.texture;
            }
            if( skintone === 3 ) {
                this.man.texture = PIXI.Loader.shared.resources.bmMan3.texture;
            }
            if( skintone === 4 ) {
                this.man.texture = PIXI.Loader.shared.resources.bmMan4.texture;
            }
            if( skintone === 5 ) {
                this.man.texture = PIXI.Loader.shared.resources.bmMan5.texture;
            }
            if( skintone === 6 ) {
                this.man.texture = PIXI.Loader.shared.resources.bmMan6.texture;
            }
        }
    }

    
    noScroll(ev) {
        ev.preventDefault()
//        console.log(ev)
//        window.scrollTo(0, this.scrollPos)
    }



    //--
    //--    createCameraTexture
    //--
    async createCameraTexture(video,camSettings) {
        
        console.log("cameraReady - creating texture 1")
        
        try 
        {
            if( this.camTextureCreated === null ) {
                this.camTextureCreated = 1

                console.log("cameraReady - creating texture 2")

                loadApi.getState().setCamSettings(camSettings)
                
    //            console.log(video)
                console.log(camSettings)

                let manY = this.man.y
                let vcont = new PIXI.Container();
                vcont.x = 0
                vcont.y = manY

                let camTexture = null;
                camTexture = PIXI.Texture.from(video);
                var videoSprite = new PIXI.Sprite(camTexture);

                videoSprite.width = camSettings.width;
                videoSprite.height = camSettings.width/camSettings.aspectRatio;

                videoSprite.x = 0;
                videoSprite.y = 0;
                videoSprite.scale.x *= -1.0;
                videoSprite.x += videoSprite.width/2;
                videoSprite.y -= videoSprite.height/2;

                console.log(videoSprite.x,videoSprite.y,videoSprite.width,videoSprite.height," camSettings.width:",camSettings.width, " camSettings.aspectRatio:",camSettings.aspectRatio)

                let manO = JSON.parse(JSON.stringify(this.manO))

                console.log("this.manO - 2 :", manO)

                var ratio = manO.w/videoSprite.width;
                let ratio2 = window.screen.width / videoSprite.width
                loadApi.getState().cameraTextureScaleFactor = ratio2
                console.log("scale ratio for video texture:",ratio, " ratio2:", ratio2 , " window.screen.width:", window.screen.width, " videoSprite.width:", videoSprite.width)
    //            vcont.scale.x = vcont.scale.y = ratio*0.7
                //-- use a global var for cameratexture scale factor
                vcont.scale.x = vcont.scale.y = ratio2 * loadApi.getState().cameraScaleFactor
                vcont.addChild(videoSprite);

                //-- add mask, no fixed size!
                //-- size of mask is related to MAN width
    //            let radius = Math.round(130)
                let radius = Math.round(400*ratio)
                let circ = new PIXI.Graphics()
                circ.beginFill(0xff0000)
                circ.drawCircle(0,0,manO.w*0.22)    //-- related to man width!
                circ.endFill()
                circ.x = 0
                circ.y = manY
    //            circ.scale.set(0,0)
                this.photo.addChildAt(circ)

                console.log(circ)

                vcont.mask = circ
                vcont.alpha = 0

                this.photo.addChild(vcont)

                this.photo.containerUpdateTransform()

                gsap.to(vcont,{duration:0.9,alpha:1,
                    onUpdateParams:[vcont,camSettings,videoSprite,camTexture,circ,manO],onUpdate:(vcont,camSettings,videoSprite,camTexture,circ,manO)=>{
//                        circ.x = this.photo.x
//                        circ.y = this.photo.y + this.man.y

                        videoSprite.width = camSettings.width;
                        videoSprite.height = camSettings.width/camSettings.aspectRatio;

                        var ratio = manO.w/videoSprite.width;
                        let ratio2 = window.screen.width / videoSprite.width

                        videoSprite.x = 0;
                        videoSprite.y = 0;
                        //                        videoSprite.scale.x *= -1.0;
                        videoSprite.x += videoSprite.width/2;
                        videoSprite.y -= videoSprite.height/2;

                        vcont.scale.x = vcont.scale.y = ratio2 * loadApi.getState().cameraScaleFactor

//                        vcont.y = manO.y
                        
                        this.photo.containerUpdateTransform()
                    },
                    onCompleteParams:[vcont,camSettings,videoSprite,camTexture,circ,manO],onComplete:(vcont,camSettings,videoSprite,camTexture,circ,manO)=>{
//                        camTexture.update()
//                        circ.x = this.photo.x
//                        circ.y = this.photo.y + manO.y 

                        videoSprite.width = camSettings.width;
                        videoSprite.height = camSettings.width/camSettings.aspectRatio;

                        var ratio = manO.w/videoSprite.width;
                        let ratio2 = window.screen.width / videoSprite.width
        
                        videoSprite.x = 0;
                        videoSprite.y = 0;
//                        videoSprite.scale.x *= -1.0;
                        videoSprite.x += videoSprite.width/2;
                        videoSprite.y -= videoSprite.height/2;

                        vcont.scale.x = vcont.scale.y = ratio2 * loadApi.getState().cameraScaleFactor

//                        vcont.y = manO.y

                        this.photo.containerUpdateTransform()

                        if(this.props.onCameraIsReady)this.props.onCameraIsReady(true)
                }})
    /*
                gsap.to(circ.scale,{duration:1,x:1,y:1,onCompleteParams:[videoSprite],onComplete:(vsprite)=>{
                    //-- from within here!
                    if(this.props.onCameraIsReady)this.props.onCameraIsReady(true)
                }})
    */
            }
            else {
                if(this.props.onCameraIsReady)this.props.onCameraIsReady(true)
                console.error("NO CAM TEXTURE")
            }
        } catch(exc) {
            window.alert(exc)
        }
    }

    onPermissionDenied(errName,errMsg) {
        console.error("onPermissionDenied:")
        window.alert(errName + " - " + errMsg)
    }
    onNotSupported(errName,errMsg) {
        console.error("onNotSupported:")
        window.alert("Camera is not supported, use other device.")
    }
    onNumCameras(nc) {
        console.log("onNumCameras:",nc)
    }
    cameraReady(camSettings) {
        //-- find video element
//        const video = document.getElementById('wcamvideo');
//        let video = this.ccc.current.getVideoRef()
        let video = this.video.current
        if(video) {
            this.createCameraTexture(video,camSettings)
        }
        else {
            window.alert(" ***** NO video id found!")
        }
    }

    render() {
        let mstyle = {}
        if(this.props.zIndex)mstyle.zIndex=this.props.zIndex

		let mirrored = this.state.currentFacingMode === 'user' ? true : false
		let fmode = (mirrored ? '180deg' : '0deg')
		let transform = "rotateY("+fmode+")"

        return (
            <>
                {this.props.foto &&
        		<div style={{position:"absolute",left:"0px",top:"0px",width:"100%",height:"100%"}}>
		            <video 
		            	id="wcamvideo" 
	            		autoPlay={true} 
	            		ref={this.video} 
	            		style={{position:"absolute",width:"100%",height:"100%",transform:transform,objectFit:"cover",opacity:1,zIndex:-1}} 
	//            		style={{position:"absolute",opacity:0,zIndex:0}} 
	            	/>
	            </div>}

                <div className={this.props.className} ref={this.cRef} style={mstyle}></div>

            </>
        )
    }
}

export default ManipulationSceneCamera;