import React,{ useState, useEffect, useRef } from "react";
import {
  useNavigate,
  useLocation
} from "react-router-dom";

import { gsap } from "gsap/all";
import { v4 as uuidv4 } from 'uuid';

import { useLoadApi } from '../store/preloaderstore'
import { useAppApi, appApi } from './../store/appstore'
import ManipulationSceneCamera from "../components/ManipulationSceneCamera"

import StdBtnPrevArrow from '../components/StdBtnPrevArrow'
import StdBtnGenericText from '../components/StdBtnGenericText'
import TotalStepsBottom from '../components/TotalStepsBottom'
import SpeechBubble from '../components/SpeechBubble'
import BackgroundRoundedUnder from '../components/BackgroundRoundedUnder'
import ColorTintSelector from '../components/ColorTintSelector'
import TopSpace from '../components/TopSpace'

const axios = require('axios').default;
const ls = require('local-storage');

export default function EditSkinTonePage() {
  let navigate = useNavigate();
  let location = useLocation();

//  const skintone = useAppApi(state => state.skintone)

  const page = useRef(null);
  const mscene = useRef(null);
  const bubble3 = useRef(null);
  const bgUnder = useRef(null);
  const tpSpace = useRef(null);

  const btnWrap = useRef();
  const btnPrev = useRef();
  const btnNext = useRef();

  useEffect(() => {
    setBGRemImage()
    bubble3.current.start()
    bgUnder.current.update()
    tpSpace.current.update()

    adjustIframeHeight()
  },[]);

  const adjustIframeHeight = () => {
    const ph = gsap.getProperty(page.current,"height")
    console.log(ph)
    if ('parentIFrame' in window) {
      const ph = gsap.getProperty(page.current,"height")
      window.parentIFrame.size(parseInt(ph)); // Set height to 100px
    }
  }

  const setBGRemImage = () => {
    let imgd = useLoadApi.getState().imgData
    if(imgd) {
      mscene.current.createFromBase64( imgd )
    } else {
      let dest=useLoadApi.getState().getDestURL(),uuid=ls.get("uuid"),u
      console.log(uuid)
      if( (""+uuid).length > 10 ) u=dest+"/getremfile/rem-"+uuid+".png"
      else if(window.location.href.indexOf("lentfert")>-1) u = 'http://lentfertnuc/bmlabel/testimg'
      axios.get(u,{
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(res => {
        if(res.data) {
          if(res.data.hasOwnProperty("code") && res.data.code === 200 ) {
            mscene.current.createFromBase64( res.data.imgdata )
          }
        }
      }).catch((error) => {
        console.log(error);
        return error;
      })
    }
  }

  const manReady = (manO) => {
    console.log("manReady:",manO)
  }

  const onPrevious = (ev) => {
    navigate("/editmoustache")
  }
  const onNext = (ev) => {
    navigate("/video3")
  }

  return (
    <div className="page" ref={page}>

      <SpeechBubble nr={3} className="speechbubble3" ref={bubble3}>
        <div className="text">
        match your<br />
        skintone
        </div>
      </SpeechBubble>

      <ManipulationSceneCamera 
        className="cwrapcam"
        zIndex={7}
        foto={false} 
        editfoto={true} 
        editmode={false} 
        editmoustache={true} 
        yoffset={0} 
        autoTouch={true}
        ref={mscene} 
        componentReady={manReady} 
      />
      <ColorTintSelector />

      <TopSpace className="" ref={tpSpace} />
      <div className="txtSkintone">finishing touches</div>
      <div className="skintone-mid">
        <div className="btnstwrapper" ref={btnWrap}>
          <StdBtnPrevArrow className="btnprev dropshadow-bottom" caption="" onClick={onPrevious} ref={btnPrev} />
          <StdBtnGenericText className="btnnext dropshadow-bottom" caption="NEXT" onClick={onNext} ref={btnNext} />
        </div>
      </div>
      <TotalStepsBottom currentStep={3} />
      <br />
      <br />

      <BackgroundRoundedUnder ref={bgUnder} />

      
      <div className="bg-img-blur"><img src="gfx/bg-blurred.webp" className="inner" style={{marginTop:"-150px"}} /></div>

    </div>
  );
}
