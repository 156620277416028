/*
    
    https://github.com/TJCoding/Enhanced-Image-Colour-Transfer-2
    
*/
import React from 'react';
import { gsap } from "gsap/all";
import * as PIXI from 'pixi.js-legacy'
import { PixiPlugin } from "gsap/PixiPlugin";
//import hotkeys from 'hotkeys-js';
import { isMobile } from 'mobile-device-detect';
import { loadApi } from '../store/preloaderstore'
import { appApi } from '../store/appstore'
import SpeechBubble from './SpeechBubble'

import './WaitingSpriteAnimation.css';

class WaitingSpriteAnimation2 extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};

        this.cRef = React.createRef();
        this.bubble = React.createRef();

        this.PConfig = {
            forceCanvas: true,
            backgroundAlpha:0,
            resolution: 1,
            antialias:true,
            autoResize:false,
            autoDensity: false,
            width:window.screen.width,
            height:window.screen.height,
        }
        this.app = new PIXI.Application(this.PConfig);
    }
    
    componentDidMount() {
        console.log("WaitingSpriteAnimation -> componentDidMount")
        
        let ww = window.screen.width, wh = window.screen.height, fact = 1

        try {


            var tsp = PIXI.AnimatedSprite.fromFrames(loadApi.getState().waitAnim);
            tsp.loop = false
            let spW = tsp.width, spH = tsp.height
//            tsp.animationSpeed = 0.5;
            tsp.animationSpeed = 0.4;
            tsp.anchor.set(0.5,1);
            fact = ww / spW
            tsp.width = ww
            tsp.height = tsp.height * fact
            tsp.x = (ww/2);
            tsp.y = (wh/2) + (tsp.height/2) + (tsp.height/2);
            tsp.gotoAndPlay(0);
            tsp.onComplete=()=>{
//                tsp.currentFrame = tsp.totalFrames - 9
                tsp.gotoAndPlay(tsp.totalFrames - 10)

            }
            this.tsp = tsp
            this.app.stage.addChild(tsp)

            this.placeBottleAtTop(tsp.x,tsp.y,fact)

        } catch(err){ console.error(err)}
        
        this.cRef.current.appendChild(this.app.view)
        console.log(fact)
        this.bubble.current.setXY(ww*0.24,(wh/2)+(190*fact)+(tsp.height/2))
        this.bubble.current.start(0.9)
    }
    componentWillUnmount() {
        if(this.app) {
            this.tsp.stop();
            this.cRef.current.removeChild(this.app.view)
            this.tsp = null;
        }
    }

    placeBottleAtTop(ax,ay,fact) {
        console.log("ay:",ay)
        try {
            var bcont = new PIXI.Container()

            var tsp = PIXI.AnimatedSprite.fromFrames(loadApi.getState().bottleAnim);
            tsp.loop = true
            tsp.animationSpeed = 0.5;
            tsp.anchor.set(0.5,1);
//            tsp.x = (window.innerWidth/2);
//            tsp.y = (window.innerHeight/2) + tsp.height/2;
            tsp.y = 0// (window.innerHeight/2) + tsp.height/2;
            tsp.play();
//            tsp.angle = 10;
//            this.app.stage.addChild(tsp)
            bcont.addChild(tsp)

            var puf1 = PIXI.Sprite.from(PIXI.Loader.shared.resources.pufR.texture);
            puf1.alpha = 0
            puf1.width *= 0.25
            puf1.height *= 0.25
//            puf1.x = (window.innerWidth/2);
            puf1.y = (window.innerHeight/2) - puf1.height;
//            this.app.stage.addChild(puf1)
            bcont.addChild(puf1)

            var puf2 = PIXI.Sprite.from(PIXI.Loader.shared.resources.pufL.texture);
            puf2.alpha = 0 
            puf2.width *= 0.25
            puf2.height *= 0.25
            puf2.anchor.set(0.2,0);
//            puf2.x = (window.innerWidth/2) - puf2.width;
            puf2.y = (window.innerHeight/2) - puf2.height*0.2;
//            this.app.stage.addChild(puf2)
            bcont.addChild(puf2)

            var puf3 = PIXI.Sprite.from(PIXI.Loader.shared.resources.pufB.texture);
            puf3.width *= 0.25
            puf3.height *= 0.25
//            puf3.x = (window.innerWidth/2) - puf2.width/2;
            puf3.y =  - 27;
//            this.app.stage.addChild(puf3)
            bcont.addChild(puf3)
            console.log(">>> ",ay, bcont.height)
            let s = fact*2.3

            bcont.scale.set(s,s)
//            bcont.x = bcont.width/2
            bcont.x = window.innerWidth/2
            bcont.y = (window.innerHeight/2) - 20*s
            this.app.stage.addChild(bcont)
            
            var tl = gsap.timeline({repeat: -1});
            tl.to(tsp, {angle:4, duration: 1, ease: "circ.out", onStart:()=>{
                //-- puf 1
                gsap.set(puf1, {x:0, y:0 - puf1.height*3, alpha:0 });
                gsap.to(puf1, {delay:0.2, x: + 50, y: 0 - puf1.height*3 - 60, duration: 1});
                gsap.to(puf1, {delay:0.2, alpha: 1, duration: 0.5});
                gsap.to(puf1, {delay:1.5, alpha: 0, duration: 1 });
            }});
            tl.to(tsp, {angle: 0, duration: 1, ease: "circ.in"});
            tl.to(tsp, {angle: -4, duration: 1, ease: "circ.out", onStart:()=>{
                //-- puf 2
                gsap.set(puf2, {x: 0 - puf2.width/2,y:-puf2.height*2, alpha:0 });
                gsap.to(puf2, {delay:0.2, x: - puf2.width/2 - 90,  duration: 1});
                gsap.to(puf2, {delay:0.2, alpha: 1, duration: 0.5});
                gsap.to(puf2, {delay:1.5, alpha: 0, duration: 1 });
            }});
            tl.to(tsp, {angle: 0, duration: 1, ease: "circ.in"});
        } catch(err){ console.error(err)}

    }

    render() {
        let mstyle = {}
        if(this.props.zIndex)mstyle.zIndex=this.props.zIndex
        return (
            <div className="wsanim" style={mstyle}>
                
                <SpeechBubble nr={2} className="speechbubbleWA dropshadow-bottom" ref={this.bubble}>
                    <div className="text">
                    Making sure<br />
                    you look<br />
                    fantastic!
                    </div>
                </SpeechBubble>

                <div className="canvaswrap" ref={this.cRef}></div>
            </div>
        )
    }
}

export default WaitingSpriteAnimation2;