import React,{ useState, useEffect, useRef } from "react";
import {
  useNavigate,
  useLocation
} from "react-router-dom";

import { gsap } from "gsap/all";

import { useLoadApi } from '../store/preloaderstore'
import { useAppApi, appApi } from '../store/appstore'
import ManipulationSceneCameraLabel from "../components/ManipulationSceneCameraLabel"
import ManipulationSceneEndLabel9x16 from '../components/ManipulationSceneEndLabel9x16'
import SpeechBubble from '../components/SpeechBubble'
import StdBtnDownloadOrShare from '../components/StdBtnDownloadOrShare'
import StdBtnGenericText from '../components/StdBtnGenericText'

import SharePopUp from '../components/SharePopUp'

import './ShowStories.css'

const axios = require('axios').default;
const ls = require('local-storage');

const useBiggerRender = true

export default function ShowStoriesPage(props) {
  let navigate = useNavigate();

  const page = useRef(null);
  const mscene = useRef(null);
  const bgUnder = useRef(null);
  const bubble = useRef(null);
  const imageRef = useRef();
  const imgWrpRef = useRef()
  const image2Ref = useRef();
  const image3Ref = useRef();
  
  const refSPop = useRef(null);
  const btnWrap = useRef(null);
  const btnPrev = useRef();
  const btnNext = useRef();


  const showbottleanim = useAppApi(state => state.showbottleanim)

  const [translateName,setTranslateName] = useState(true)
  const [hastransname, setHasTransName] = useState(true);
//  const [bdvalue, setBDValue] = useState();
  const [plname, setPLName] = useState("");
  const [pldal, setPLDal] = useState("");
  const [nameStory,setNameStory] = useState("")
  const [bdstory, setBDStory] = useState("");
  const [shareText,setShareText] = useState("")
  const [renderimg, setRenderImg] = useState();
  const [renderimg2, setRenderImg2] = useState();
  const [renderimg3, setRenderImg3] = useState();
  const style = useRef({top:Math.floor(window.screen.width*1.77)})

  const renderW = window.screen.width,
    renderH = Math.floor(renderW*1.77*1.52),
    renderMultiplier = 2

  useEffect(() => {
    let name = appApi.getState().playerName
    if( 1 === 0 ) {
      name = "kim"
      appApi.getState().hasNameMatch("kim")
      appApi.getState().playerDate = "2005-10-02"
    }
    mscene.current.setNameText(name.toUpperCase() + "")
    mscene.current.setYear(appApi.getState().playerYear)
    setPLName(name.toUpperCase() + "")
    let plDate = appApi.getState().playerDate
    let normPLDate = plDate.split("-").reverse().join("-")
    console.log("normPLDate:",normPLDate, " plDate:",plDate)
    setPLDal(normPLDate)
    
    //    let shareTextComplete = "An icon was born\n\n" + name.toUpperCase() + " MORETTI " + "\n\n DAL " + normPLDate + "\n\n\n"
    let shareTextComplete = "An authentic Italian\n\n" + name.toUpperCase() + "" + "\n\n DAL " + normPLDate + "\n\n\n"
    let bddata = appApi.getState().getBDStory(normPLDate)
    console.log("bddata:",bddata)
    let ndata = appApi.getState().curNameMatchData
    if( ndata !== null && bddata !== null ) {
      setHasTransName(true)
      setNameStory(ndata.S)
      setBDStory(bddata.S)
      
      shareTextComplete +=  "Your beautiful italian name \n\n"  + ndata.S + " \n\n\ncelebrate your birthday the italian way \n\n" + bddata.S
      setShareText(shareTextComplete)
    } else {
      setHasTransName(false)
      if(bddata) {
        setNameStory(bddata.S)
        shareTextComplete += "celebrate your birthday the italian way \n\n" + bddata.S
      }
      setShareText(shareTextComplete)
    }
    setBGRemImage()

    adjustIframeHeight()
  },[]);

  useEffect(() =>{
    if(showbottleanim === -1 ) {
      console.log("ok now bubble")
      bubble.current.show()
    }
  },[showbottleanim])
  
  const adjustIframeHeight = () => {
    if ('parentIFrame' in window) {
      window.parentIFrame.scrollTo(0,0)
      const ph = gsap.getProperty(page.current,"height")
      window.parentIFrame.size(parseInt(ph)); // Set height to 100px
    }
}

  const setBGRemImage = () => {
    let imgd = useLoadApi.getState().imgData
    //-- force loading!
    if( imgd ) {
      try {
        mscene.current.createFromBase64( imgd )
/*
        //-- get render?
        gsap.delayedCall(0.2,()=>{
          let finImg = mscene.current.getFinalCompositionImage()
          setRenderImg(finImg)
        })
*/
      }catch(e) {
        alert(e)
      }
    } else {
      let dest=useLoadApi.getState().getDestURL(),uuid=ls.get("uuid"),u
      if( (""+uuid).length > 10 ) u=dest+"/getremfile/rem-"+uuid+".png"
      else if(window.location.href.indexOf("lentfert")>-1) u = 'http://lentfertnuc/bmlabel/testimg'
      if(props.usetestimg === 1 && window.location.href.indexOf("192.168.1.194")>-1)u = 'http://192.168.1.194/bmlabel/testimg'
      axios.get(u,{
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(res => {
        if(res.data) {
          if(res.data.hasOwnProperty("code") && res.data.code === 200 ) {
            mscene.current.createFromBase64( res.data.imgdata )

            //-- get render? NOT HERE! WAIT FOR IMAGE IS LOADED
        
            /*
            gsap.delayedCall(0.2,()=>{
              let finImg = mscene.current.getFinalCompositionImage()
              setRenderImg(finImg)
            })
*/
          }
        }
      }).catch((error) => {
        console.log(error);
        window.alert(error)
        return error;
      })
    }
  }

  const loadingFaceReady = async () => {
    try {
      let finImg = mscene.current.getFinalCompositionImage()
//      console.log(finImg)
      setRenderImg(finImg)
//      await renderAllTogether(finImg)
      await renderTextOnly()

      adjustIframeHeight()
  //      console.log()
    }catch(e) {
      alert(e)
    }
  }
/*
  const DataURIToBlob = (dataURI) => {
    const splitDataURI = dataURI.split(',')
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? window.atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0]
    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++)
        ia[i] = byteString.charCodeAt(i)
    return new Blob([ia], { type: mimeString })
  }
*/
  const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(","),
      mimeType = arr[0].match(/:(.*?);/)[1],
      decodedData = atob(arr[1]),
      lengthOfDecodedData = decodedData.length,
      u8array = new Uint8Array(lengthOfDecodedData);
    while (lengthOfDecodedData--) {
      u8array[lengthOfDecodedData] = decodedData.charCodeAt(lengthOfDecodedData);
    }
    return new File([u8array], filename, { type: mimeType });
  };

  //--
  //-- https://github.com/blueimp/JavaScript-Canvas-to-Blob
  //--
  const handleShareBottle = async () => {
    try {
      const newFile = dataURLtoFile(renderimg);
      const data = {
        files: [
          new File([newFile], 'BirraMoretti.png', {
            type: newFile.type,
          }),
        ],
//        title: 'Birra Moretti',
//        text: shareText,
      };
      if (!navigator.canShare(data)) {
        console.error("Can't share");
      }
      let ok = await navigator.share(data);
      console.log("shared:",ok)
    } catch (err) {
      console.error(err);
//      window.alert(err)
    }
  };

    //--
  //-- https://github.com/blueimp/JavaScript-Canvas-to-Blob
  //--
  const handleShareText = async () => {
    try {
      const newFile = dataURLtoFile(renderimg3);
      const data = {
        files: [
          new File([newFile], 'BirraMoretti.png', {
            type: newFile.type,
          }),
        ],
//        title: 'Birra Moretti',
//        text: shareText,
      };
      if (!navigator.canShare(data)) {
        console.error("Can't share");
      }
      let ok = await navigator.share(data);
      console.log("shared:",ok)
    } catch (err) {
      console.error(err);
//      window.alert(err)
    }
  };

  const sharePopup = (ev,dims) => {
    const ph = gsap.getProperty(page.current,"height")
    refSPop.current.show(ph,dims)
  }
  const onShareBottle = async () => {
    console.log("sharing bottle")
    await handleShareBottle()
  }
  const onShareText = async () => {
    console.log("sharing text")
    await handleShareText()
  }

  const renderAllTogether = async (rimg) => {
    let fImgData = await mscene.current.renderDynamicTextToCanvas(rimg,hastransname,pldal,nameStory,bdstory)
    if(fImgData)
      setRenderImg2(fImgData)
  }
  const renderTextOnly = async () => {
    let fImgData = await mscene.current.renderDynamicTextToCanvas(undefined,hastransname,pldal,nameStory,bdstory)
    if(fImgData)
      setRenderImg3(fImgData)
  }
  
  const onPrevious = (ev) => {
    navigate("/editbirthdate")
  }
  const onNext = (ev) => {
  }

  const componentReady = (manO) => {
  }

  const onStart = (ev) => {
    navigate("/")
//    window.location = window.location.protocol + '//' + window.location.host + window.location.pathname 
  }

  return (
    <div className="page" ref={page}>

      <SpeechBubble nr={2} className="speechbubbleLG dropshadow-bottom" ref={bubble}>
        <div className="text">
        you look<br />
        amazing!
        </div>
      </SpeechBubble>

      <div ref={imgWrpRef} className="imgwrapper">
        <img src={renderimg} ref={imageRef} />
      </div>
      <img src={renderimg2} style={{position:"absolute",opacity:0,zIndex:-1,left:"0px", width:"100%"}} ref={image2Ref} />
      <img src={renderimg3} style={{position:"absolute",opacity:0,zIndex:-1,left:"0px", width:"100%"}} ref={image3Ref} />

    <ManipulationSceneEndLabel9x16
        className="canvasstories-2" 
        showLabel={true} 
        yoffset={0} 
        renderBottle={1} 
//        canvwidth={1080*renderMultiplier} 
//        canvheight={1920*renderMultiplier} 
        canvwidth={1080} 
        canvheight={1920} 
        renderMultiplier={1} 
        componentReady={componentReady} 
        loadingFaceReady={loadingFaceReady}
        ref={mscene} 
      />
{/*      
    <ManipulationSceneEndLabel
        className="canvasstories-2" 
        showLabel={true} 
        yoffset={0} 
        renderBottle={1} 
        canvwidth={renderW*renderMultiplier} 
        canvheight={renderH*renderMultiplier} 
        renderMultiplier={renderMultiplier} 
        componentReady={componentReady} 
        loadingFaceReady={loadingFaceReady}
        ref={mscene} 
      />
*/}
      <div className="bg-img-blur-2"><img src="gfx/bg-blurred.webp" className="inner" style={{marginTop:"-150px"}} /></div>

      {/* stories etc */}
      <SharePopUp ref={refSPop} 
        onShareBottle={onShareBottle}
        onShareText={onShareText}
      />
      <StdBtnDownloadOrShare className="btn-dorshare dropshadow-bottom" onClick={sharePopup} />

{ 1 === 1 && <>
      <div className="stories">
        <div className="nameTitle">an authentic italian</div>
        <div className="plname">{plname}</div>
        <div className="pldal">{pldal}</div>
      </div>
      <div className="brownblock">
          {hastransname && <div className="celebratebb">Your beautiful<br />italian name</div>}
          {!hastransname && <div className="celebratebb">celebrate your birthday the italian way</div>}
          <br />
          <div className="celebrate-bodybb">{nameStory}</div>
      </div>
      {hastransname && <>
        <div className="celebrate">celebrate your birthday the italian way</div>
        <div className="celebrate-body">{bdstory}</div>
      </>}
</>}
{/*
      <img className="signature" src="gfx/signature.png" />
*/}
      <br />
      <div className="btnstartwrapper" ref={btnWrap}>
        <StdBtnGenericText className="btnstarthome" classNameExtra="dropshadow-bottom" caption="START AGAIN" onClick={onStart} />
      </div>
      <br />
      <br />
    </div>
  );
}
