import React,{ useRef, useState, useEffect } from "react";
import {
  useNavigate,
  useLocation
} from "react-router-dom";
import { gsap } from "gsap/all";

class SpeechBubble extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}

        this.elRef = React.createRef();
        this.innerboxRef = React.createRef();
    }

    componentDidMount() {
        gsap.set(this.elRef.current,{scale:0});
    }
    componentWillUnmount() {
    }
    componentDidUpdate(prevProps, prevState) {
    }

    setXY(x,y) {
        gsap.set(this.elRef.current,{x:x,y:y})
    }

    start(del) {
        gsap.set(this.elRef.current,{display:"block"});
        gsap.to(this.elRef.current,{delay:del,duration:1,scale:1,ease: "elastic.out(1, 0.3)"})
    }

    show(del) {
        if(del===undefined)del = 0.1;
        gsap.set(this.elRef.current,{display:"block"});
        gsap.to(this.elRef.current,{delay:del,duration:1,scale:1,ease: "elastic.out(1, 0.3)"})
    }
    hide() {
        gsap.set(this.elRef.current,{display:"none",scale:0});
    }

    getB() {
        return this.elRef.current.getBoundingClientRect();
    }

    clickToHide(ev) {
        if( this.props.onCloseClick ) {
            this.hide()
            this.props.onCloseClick()
        }
    }
    
    isVisible() {
        if( gsap.getProperty(this.elRef.current, "display") === "block" ) return true
        return false
    }
    
    render() {
        return (
            <div ref={this.elRef} className={this.props.className} onClick={this.clickToHide.bind(this)}>
                <div ref={this.innerboxRef} className={this.props.innercls} >
                    {this.props.children}
                </div>
            </div>
        );
    }

}
export default SpeechBubble;